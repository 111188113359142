export enum HttpMethods {
    Post = 'POST',
    Get = 'GET',
    Delete = 'DELETE',
    Put = 'PUT',
}

export type IApiResponse = {
    success: boolean,
    result?: {
        [key: string]: any;
    }
    errors?: any[];
}
