import {IApiResponse} from "../../../types/http";
import {postRequestRestoreToken} from "../../../http/auth";

export const requestRestoreToken = async (email: string) => {
    const response = await fetch(postRequestRestoreToken.url, {
        method: postRequestRestoreToken.method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({email})
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.data
}
