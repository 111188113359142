import React, {ChangeEvent} from "react";
import {ListItem, TextField, List} from "@mui/material";
import {IDescription} from "../../../types";
import {handleChangeDescription} from "../../../stores/routine/handlers/handleChangeDescription";
import {Translation} from "../../../library/common/Translation/Translation";
import {useStore} from "effector-react";
import {$routineStore} from "../../../stores";


const Component = () => {
    const routine = useStore($routineStore);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newDescription = {...routine.description};
        const key = e.target.id as keyof IDescription
        newDescription[key] = e.target.value;
        handleChangeDescription(newDescription);
    }

    return (
        <List component="div" disablePadding>
            <ListItem key='teamName'>
                <TextField
                    required
                    id="team"
                    label={<Translation nameSpace='common' translation="domain.team"/>}
                    value={routine.description.team}
                    onChange={handleChange}
                    sx={{
                        marginLeft: 1,
                        marginRight: 1,
                    }}
                />
            </ListItem>
            <ListItem key='competitionName'>
                <TextField
                    required
                    id="competition"
                    label={<Translation nameSpace='common' translation="domain.competition"/>}
                    value={routine.description.competition}
                    onChange={handleChange}
                    sx={{
                        marginLeft: 1,
                        marginRight: 1,
                    }}
                />
            </ListItem>
            <ListItem key='themeName'>
                <TextField
                    required
                    id="theme"
                    label={<Translation nameSpace='common' translation="domain.theme"/>}
                    value={routine.description.theme}
                    onChange={handleChange}
                    sx={{
                        marginLeft: 1,
                        marginRight: 1,
                    }}
                />
            </ListItem>
            <ListItem key='athletesNames'>
                <TextField
                    required
                    id="athletes"
                    label={<Translation nameSpace='common' translation="domain.athletes"/>}
                    multiline
                    value={routine.description.athletes}
                    onChange={handleChange}
                    sx={{
                        marginLeft: 1,
                        marginRight: 1,
                    }}
                />
            </ListItem>
        </List>
    )
}


export const MainData = React.memo(Component)
