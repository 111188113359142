import {FC, memo, useEffect} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Box} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {setJWTToken} from "../../services/setJWTToken";
import {validateRefreshToken} from "../../services/apiRequests/auth/validateRefreshToken";

const Component: FC = () => {
    const navigate = useNavigate()
    const {token} = useParams()

    useEffect(()=>{
        validateRefreshToken(token!).then(accessToken=>{
            setJWTToken(accessToken!)
        })
        navigate('/calculator');
    }, [token, navigate])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                Если ваш браузер не поддерживает переадресацию
                <Button>Нажмите здесь</Button>
            </Box>
        </Container>
    );
}

export const PostAuth = memo(Component);
