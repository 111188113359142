import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import {SignIn} from "./Login/SignIn";
import {SignUp} from "./Login/SignUp";
import {Calculator} from "./Calculator/Calculator";

import "../i18n";
import {RequestRestorationEmail} from "./Login/RequestRestorationEmail";
import {SetNewPassword} from "./Login/SetNewPassword";
import {PostAuth} from "./Login/PostAuth";
// import {ProfileLayout} from "./Profile/ProfileLayout";
// import {PoolSpaceLayout} from "./PoolSpace/Layout";
const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/auth'>
                    <Route path="signup" element={<SignUp/>}/>
                    <Route path="login" element={<SignIn/>}/>
                    <Route path="restore" element={<RequestRestorationEmail/>}/>
                    <Route path="changePassword/:token" element={<SetNewPassword/>}/>
                    <Route path="postauth/:token" element={<PostAuth/>}/>
                </Route>
                <Route path='/calculator' element={<Calculator/>}/>
                <Route path="/profile" element={null}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
