export const GROUP_AJ = {
    name: 'Group A, Jump',
    code: 'AJ',
    description: 'All elements in this group are performed by a “featured-swimmer” in the air (Jumps and throws)',
    construction: [
        {
            name: 'Throw from surface',
            code: 'Surf',
            value: 0.5,
        }, {
            name: 'Simple throw',
            code: 'Thr',
            value: 0.9,
        }, {
            name: 'Simple throw (6-9 base swimmer)',
            code: 'Thr',
            value: 0.9,
        }, {
            name: 'Jump from shoulders (stack type)',
            code: 'Shou',
            value: 1.25,
        }, {
            name: 'Jump from shoulders (small type)',
            code: 'Sho',
            value: 1.0,
        }, {
            name: 'Jump from hands',
            code: 'Hand',
            value: 1.35,
        }, {
            name: 'Jump from feet (stack type/6-9 base)',
            code: 'Feet',
            value: 1.35,
        }, {
            name: 'Jump from square ("basket")',
            code: 'Sq',
            value: 1.35,
        }, {
            name: 'Jump from 2 formations',
            code: '2Form',
            value: 1.3,
        }, {
            name: 'Jump from two supports + "spotter"',
            code: '2Sup\'',
            value: 1.3,
        }, {
            name: '"Triple" throw',
            code: 'Tripl',
            value: 1.35,
        }, {
            name: 'Jump from 3 pairs',
            code: '3Pair',
            value: 1.0,
        }, {
            name: 'Stack + spotter',
            code: 'St\'',
            value: 1.3,
        }, {
            name: 'Throw from surface (small)',
            code: 'surf',
            value: 0.5,
        }, {
            name: 'Jump from feet (stack type/small)',
            code: 'feet',
            value: 1.1,
        }
    ],
    direction: [
        {
            name: 'Upwards',
            code: 'Up',
            value: 0.05,
        }, {
            name: 'Forwards (no som/tw - just twist 180° or som 0.5, handspring or cartwheel)',
            code: 'Forw',
            value: 0.05,
        }, {
            name: 'Backwards',
            code: 'Back',
            value: 0.1,
        }, {
            name: 'Forwards (with 1+ somersault or 1+ twist or both)',
            code: 'FORW',
            value: 0.15,
        }, {
            name: 'Sideways',
            code: 'Side',
            value: 0.2,
        }, {
            name: 'Reverse',
            code: 'Rev',
            value: 0.2,
        },
    ],
    position: [
        {
            name: 'Kick',
            code: 'ki',
            value: 0.05
        },{
            name: 'Kick (pos 2)',
            code: '2ki',
            value: 0.05
        },{
            name: 'Tuck',
            code: 'tk',
            value: 0.1
        },{
            name: 'Tuck (pos 2)',
            code: '2tk',
            value: 0.1
        },{
            name: 'Parrot',
            code: 'pa',
            value: 0.15
        },{
            name: 'Parrot (pos 2)',
            code: '2pa',
            value: 0.15
        },{
            name: 'Ninja',
            code: 'nj',
            value: 0.15
        },{
            name: 'Ninja (pos 2)',
            code: '2nj',
            value: 0.1
        },{
            name: 'Pike',
            code: 'pk',
            value: 0.2
        },{
            name: 'Pike (pos 2)',
            code: '2pk',
            value: 0.2
        },{
            name: 'Mantis',
            code: 'mn',
            value: 0.05
        },{
            name: 'Mantis (pos 2)',
            code: '2mn',
            value: 0.05
        },{
            name: 'Line',
            code: 'ln',
            value: 0.1
        },{
            name: 'Line (pos 2)',
            code: '2ln',
            value: 0.1
        }, {
            name: 'Line (legs-up)',
            code: 'ln⫰',
            value: 0.1
        },{
            name: 'Line (legs-up) (pos 2)',
            code: '2ln⫰',
            value: 0.1
        }, {
            name: 'Split',
            code: 'sp',
            value: 0.3
        },{
            name: 'Split (pos 2)',
            code: '2sp',
            value: 0.15
        },{
            name: 'Arch',
            code: 'ar',
            value: 0.1
        },{
            name: 'Arch (pos 2)',
            code: '2ar',
            value: 0.1
        },{
            name: 'Kite',
            code: 'kt',
            value: 0.1
        },{
            name: 'Kite (pos 2)',
            code: '2kt',
            value: 0.1
        },{
            name: 'Martin',
            code: 'ma',
            value: 0.15
        },{
            name: 'Martin (pos 2)',
            code: '2ma',
            value: 0.1
        },{
            name: 'Jay',
            code: 'ja',
            value: 0.2
        },{
            name: 'Jay (pos 2)',
            code: '2ja',
            value: 0.15
        },{
            name: 'Ring',
            code: 'rg',
            value: 0.25
        },{
            name: 'Ring',
            code: '2rg',
            value: 0.2
        }
    ],
    rotation: [
        {
            name: 'Horizontal plane (twist) for "head-up" position 180°',
            code: 'T0,5',
            value: 0.1
        },{
            name: 'Horizontal plane (twist) for "head-up" position 360°',
            code: 'T1',
            value: 0.15
        },{
            name: 'Horizontal plane (twist) for "head-up" position 540°',
            code: 'T1,5',
            value: 0.2
        },{
            name: 'Horizontal plane (twist) for "head-up" position 720°',
            code: 'T2',
            value: 0.25
        },{
            name: 'Horizontal plane (twist) when twist executed in the same time with\n' +
                'somersault and other "not head-up" twists  180°',
            code: 't0,5',
            value: 0.1
        },{
            name: 'Horizontal plane (twist) when twist executed in the same time with\n' +
                'somersault and other "not head-up" twists  360°',
            code: 't1',
            value: 0.2
        },{
            name: 'Horizontal plane (twist) when twist executed in the same time with\n' +
                'somersault and other "not head-up" twists  540°',
            code: 't1,5',
            value: 0.3
        },{
            name: 'Horizontal plane (twist) when twist executed in the same time with\n' +
                'somersault and other "not head-up" twists  720°',
            code: 't2',
            value: 0.4
        },{
            name: 'Saggital plane 180°',
            code: 's0,5',
            value: 0.05,
        },{
            name: 'Saggital plane 180° (for "small" jumps)',
            code: 'S0,5',
            value: 0.2,
        },{
            name: 'Saggital plane 360°',
            code: 's1',
            value: 0.3,
        },{
            name: 'Saggital plane 540°',
            code: 's1,5',
            value: 0.5,
        },{
            name: 'Saggital plane 720°',
            code: 's2',
            value: 0.6,
        },{
            name: 'Saggital plane 900°',
            code: 's2,5',
            value: 0.8,
        },{
            name: 'Saggital plane 1080°',
            code: 's3',
            value: 1.4,
        },{
            name: 'Saggital plane handspring°',
            code: 'h',
            value: 0.1,
        },{
            name: 'Frontal plane 360°',
            code: 'f1',
            value: 0.4,
        },{
            name: 'Frontal plane 540°',
            code: 'f1,5',
            value: 0.6,
        },{
            name: 'Frontal plane 720°',
            code: 'f2',
            value: 0.7,
        },{
            name: 'Frontal plane cartwheel',
            code: 'c',
            value: 0.1,
        },{
            name: 'Frontal plane handspring',
            code: 'h',
            value: 0.1,
        },{
            name: 'Dive (not 180° somersault(',
            code: 'd',
            value: 0.025,
        },{
            name: 'Dive + 180° twist',
            code: 'dt0,5',
            value: 0.125,
        },{
            name: 'Dive + 360° twist',
            code: 'dt1,0',
            value: 0.175,
        },{
            name: 'Dive + 540° twist',
            code: 'dt1,5',
            value: 0.225,
        },{
            name: 'Two axis airborne rotation half somersault + half twist (small jumps only)',
            code: 'S0,5t0,5',
            value: 0.3,
        },{
            name: 'Two axis airborne rotation 1 somersault + 0,5 twist',
            code: 's1t0,5',
            value: 0.4,
        },{
            name: 'Two axis airborne rotation 1 somersault + 1 twist',
            code: 's1t1',
            value: 0.5,
        },{
            name: 'Two axis airborne rotation 1 somersault + 1,5 twist',
            code: 's1t1,5',
            value: 0.6,
        },{
            name: 'Two axis airborne rotation 1 somersault + 2 twist',
            code: 's1t2',
            value: 0.7,
        },{
            name: 'Two axis airborne rotation 1 somersault + 2,5 twist',
            code: 's1t2,5',
            value: 0.8,
        },{
            name: 'Two axis airborne rotation 1,5 somersault + 0,5 twist',
            code: 's1.5t0,5',
            value: 0.6,
        },{
            name: 'Two axis airborne rotation 1,5 somersault + 1 twist',
            code: 's1,5t1',
            value: 0.7,
        },{
            name: 'Two axis airborne rotation 2 somersault + 0,5 twist',
            code: 's2t0,5',
            value: 0.9,
        },{
            name: 'Two axis airborne rotation 2 somersault + 1 twist',
            code: 's2t1',
            value: 1,
        },
    ],
    bonus: [
        {
            name: 'Synchronized actions for double acrobatic movements',
            code: 'u1',
            value: 0.2,
        },{
            name: '"Opening" to straight body position after 1,5 (inside 2 somersaults)',
            code: 'u2',
            value: 0.5,
        },{
            name: 'During 1,5 somersault opening in a straight body position (1 somersault\n' +
                'and +0,5 rotation with opening to a straight body position) ',
            code: 'u3',
            value: 0.4,
        },{
            name: 'Straight body somersault',
            code: 'u4',
            value: 0.2,
        },{
            name: 'Straight body position during twist + somersault jump (start from 1\n' +
                'somersault+1,5 twist and more)',
            code: 'u5',
            value: 0.4,
        },{
            name: '"Grip" (hand connection) between featured-swimmer and support',
            code: 'u6',
            value: 0.025,
        },{
            name: '"Return" on a construction after the airborne phase ',
            code: 'u7',
            value: 0.3,
        },{
            name: 'Connection between 2 featured-swimmers (from beginning to the end)',
            code: 'u8',
            value: 0.1,
        },{
            name: 'Connection between support and featured swimmer\n' +
                '(may be "broken" before water entrance) ',
            code: 'u9',
            value: 0.025,
        },{
            name: 'Connection between 2 featured swimmers during airborne phase\n' +
                '(they connect after take-off) ',
            code: 'u10',
            value: 0.15,
        },{
            name: 'Third position (example: in the end of acrobatic movement closing legs to\n' +
                'vertical (group B) or tucking (group A)',
            code: 'u11',
            value: 0.05,
        },{
            name: 'Jump from feet (feet/feet connect between support and featured-swimmer)',
            code: 'u12',
            value: 0.1,
        },{
            name: 'Twist head-down 360',
            code: 'u13',
            value: 0.2,
        },{
            name: 'Jump from split (head-up) position',
            code: 'u14',
            value: 0.2,
        },{
            name: '"Return" on a support’s hands after the airborne phase',
            code: 'u15',
            value: 0.1,
        },{
            name: '"Twirl" of a featured-swimmer with hand connection with support-swimmer ',
            code: 'u16',
            value: 0.05,
        },
    ],
    connection: [],
    rotationOfBase: [],
}
