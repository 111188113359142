import {calculatorScope} from "./base";
import {HttpMethods} from "../types/http";

export const CALCULATE_ROUTINE_URL = `${calculatorScope}/routine`
export const CALCULATE_HYBRID_URL = `${calculatorScope}/hybrid`
export const CALCULATE_TRE_URL = `${calculatorScope}/tre`
export const CALCULATE_ACROBATICS_URL = `${calculatorScope}/acrobatics`
export const CALCULATE_PAIR_ACROBATICS_URL = `${calculatorScope}/pair-acrobatics`

export const postCalculateRoutine = {
    url: CALCULATE_ROUTINE_URL,
    method: HttpMethods.Post,
}

export const postCalculateHybrid = {
    url: CALCULATE_HYBRID_URL,
    method: HttpMethods.Post,
}

export const postCalculateTRE = {
    url: CALCULATE_TRE_URL,
    method: HttpMethods.Post,
}

export const postCalculateAcrobatics = {
    url: CALCULATE_ACROBATICS_URL,
    method: HttpMethods.Post,
}
export const postCalculatePairAcrobatics = {
    url: CALCULATE_PAIR_ACROBATICS_URL,
    method: HttpMethods.Post,
}
