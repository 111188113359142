import {ChangeEvent, FC, memo, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Box} from "@mui/material";
import {Translation} from "../../library/common/Translation/Translation";
import {requestRestoreToken} from "../../services/apiRequests/auth";


const Component: FC = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setEmail(event.target.value)
    }

    const handleRequestEmail = async ()=>{
        await requestRestoreToken(email);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{
                    margin: 1,
                    backgroundColor: 'secondary.main',
                }}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    <Translation nameSpace='auth' translation='requestRestorePassword'/>
                </Typography>
                <Box sx={{
                    width: '100%', // Fix IE 11 issue.
                    marginTop: 1,
                }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={<Translation nameSpace='auth' translation='email'/>}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleEmailChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleRequestEmail}
                    >
                        <Translation nameSpace='auth' translation='sendLink'/>
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export const RequestRestorationEmail = memo(Component)
