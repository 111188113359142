import {
    $routineStore,
    changeDescriptionEvent,
    changeRoutineTypeEvent,
    changeDurationEvent,
    addFragmentEvent,
    removeFragmentEvent, endFragmentEvent, updateCurrentFragmentEvent, loadRoutineEvent
} from "./model";

$routineStore.on(loadRoutineEvent, (_state, change) => change);

$routineStore.on(changeRoutineTypeEvent, (_state, change) => ({
        description: _state.description,
        duration: _state.duration,
        type: change,
        fragments: _state.fragments
    })
)

$routineStore.on(changeDescriptionEvent, (_state, change) => ({
        description: change,
        duration: _state.duration,
        type: _state.type,
        fragments: _state.fragments
    })
)

$routineStore.on(changeDurationEvent, (_state, change) => ({
        description: _state.description,
        duration: change,
        type: _state.type,
        fragments: _state.fragments
    })
)
$routineStore.on(addFragmentEvent, (_state, change) => {
    return {
            description: _state.description,
            duration: _state.duration,
            type: _state.type,
            fragments: [..._state.fragments, change]
                .sort((a, b) => a.startTime - b.startTime)
                .map((item,index, array)=>{
                    item.number = index;
                    if(index !== array.length-1) item.endTime = array[index+1].startTime
                    return item
                })
        }
    }
)

$routineStore.on(endFragmentEvent, (_state, change) => {
    const modifiedFragments = [..._state.fragments];
    modifiedFragments.at(-1)!.endTime = change;
    return {
        description: _state.description,
        duration: _state.duration,
        type: _state.type,
        fragments: modifiedFragments,
    }
})

$routineStore.on(removeFragmentEvent, (_state, change) => ({
        description: _state.description,
        duration: _state.duration,
        type: _state.type,
        fragments: _state.fragments
            .filter((item) => item.number !== change.number)
            .map((item, index,array) => {
                item.number = index;
                if(index !== array.length-1) item.endTime = array[index+1].startTime
                return item
            })
    })
)

$routineStore.on(updateCurrentFragmentEvent, (_state, change) => {
    if (!change) return;
    const modifiedFragments = [..._state.fragments.slice(0, change.number), change, ..._state.fragments.slice(change.number + 1)];
    return {
        description: _state.description,
        duration: _state.duration,
        type: _state.type,
        fragments: modifiedFragments,
    }
})
