import {TRE_VARIANTS} from "./TRE_VARIANTS";
import {ROUTINE_TYPES} from "../ROUTINE_TYPES";

export const TEAM_TECHNICAL_ELEMENTS = {
    TRE1a: {
        routineType: ROUTINE_TYPES.TECH_TEAM,
        TRENumber: 1,
        TREVariant: TRE_VARIANTS.A,
        description: 'Flying Fish Hybrid Spinning 180°',
    },
    TRE1b: {
        routineType: ROUTINE_TYPES.TECH_TEAM,
        TRENumber: 1,
        TREVariant: TRE_VARIANTS.B,
        description: 'Flying Fish Hybrid',
    },
    TRE2a: {
        routineType: ROUTINE_TYPES.TECH_TEAM,
        TRENumber: 2,
        TREVariant: TRE_VARIANTS.A,
        description: 'Vertical - Full Twist to Bent Knee - Full Twist to Vertical – Open 180° - Walkout',
    },
    TRE2b: {
        routineType: ROUTINE_TYPES.TECH_TEAM,
        TRENumber: 2,
        TREVariant: TRE_VARIANTS.B,
        description: 'Vertical - Half Twist to Bent Knee - Half Twist to Vertical – Split - Walkout',
    },
    TRE3a: {
        routineType: ROUTINE_TYPES.TECH_TEAM,
        TRENumber: 3,
        TREVariant: TRE_VARIANTS.A,
        description: 'Two Fouetté Rotations – Vertical – Continuous Spin 720°',
    },
    TRE3b: {
        routineType: ROUTINE_TYPES.TECH_TEAM,
        TRENumber: 3,
        TREVariant: TRE_VARIANTS.B,
        description: 'Two Fouetté Rotations – Vertical – Spinning 360°',
    },
    TRE4: {
        routineType: ROUTINE_TYPES.TECH_TEAM,
        TRENumber: 4,
        TREVariant: TRE_VARIANTS.NONE,
        description: 'Butterfly Hybrid',
    },
    TRE5a: {
        routineType: ROUTINE_TYPES.TECH_TEAM,
        TRENumber: 5,
        TREVariant: TRE_VARIANTS.A,
        description: 'Rocket Split Bent Knee Twirl Hybrid',
    },
    TRE5b: {
        routineType: ROUTINE_TYPES.TECH_TEAM,
        TRENumber: 5,
        TREVariant: TRE_VARIANTS.B,
        description: 'Rocket Split Bent Knee Hybrid',
    },
}
