import React from "react";
import {useStore} from "effector-react";
import {Typography} from "@mui/material";

import {$routineStore} from "../../../stores";
import {formatTime} from "../../../services/formatTime";
import {Translation} from "../../../library/common/Translation/Translation";

const Component = () => {
    const routine = useStore($routineStore);
    const startTime = routine.fragments.at(0)?.startTime || 0;
    const endTime = routine.fragments.at(-1)?.endTime || routine.fragments.at(-1)?.startTime || 0
    return (
        <Typography align="center" color="textPrimary">
            {routine.fragments.length ? formatTime(endTime - startTime) : <Translation nameSpace='common' translation='domain.time' />}
        </Typography>
    )
}

export const OverallTime = React.memo(Component)
