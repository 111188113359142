import React, {useState} from 'react';
import {Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import { IRoutineFragment, ITechRoutineTypes, ITRE} from "../../../types";
import SaveIcon from "@mui/icons-material/Save";
import {$currentRoutineFragmentStore, $routineStore} from "../../../stores";
import {useStore} from "effector-react";
import {TECHNICAL_ELEMENTS} from "../../../const/technicalElements";
import {
    handleSelectTechnicalElements
} from "../../../stores/currentRoutineFragment/handlers/handleSelectTechnicalElements";

type IProps = {
    onOk: ()=>void
}
const Component = (props: IProps) => {
    const currentRoutine = useStore($routineStore);
    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<ITRE>
    const [currentTE, setCurrentTE] = useState<ITRE>( {
        routineType: currentRoutineFragment.content.routineType,
        TRENumber: currentRoutineFragment.content.TRENumber,
        TREVariant: currentRoutineFragment.content.TREVariant,
        description: currentRoutineFragment.content.description,
    })

    const handleTEChange = (e: SelectChangeEvent<string>)=>{
        const possibleElements = TECHNICAL_ELEMENTS[currentRoutine.type as ITechRoutineTypes];
        const typedItem = e.target.value as keyof typeof possibleElements;
        const TRE = possibleElements[typedItem]
        setCurrentTE(TRE)
    }

    const saveTE = ()=>{
        handleSelectTechnicalElements(currentTE);
        props.onOk()
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            gap: 2,
            width: '30vw',
        }}>
            <FormControl fullWidth>
                <InputLabel id="technical-element-label">Technical Element</InputLabel>
                <Select
                    labelId="technical-element-label"
                    id="technical-element-select"
                    value={`TRE${currentTE.TRENumber}${currentTE.TREVariant}: ${currentTE.description}`}
                    label="technical-element"
                    onChange={handleTEChange}
                >
                    {Object.values(TECHNICAL_ELEMENTS[currentRoutine.type as ITechRoutineTypes]).map((item: ITRE) =>{
                        return <MenuItem value={`TRE${item.TRENumber}${item.TREVariant}`}>{`TRE${item.TRENumber}${item.TREVariant}`}: {item.description}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                size="small"
                sx={{
                    margin: 1,
                }}
                onClick={saveTE}
            >
                <SaveIcon
                    sx={{
                        marginRight: 1,
                        fontSize: 20,
                    }}
                />
                Save technical element
            </Button>
        </Box>
    )
}

export const SelectTechnicalElements = React.memo(Component);
