export enum THRUST_LEVELS {
    LEVEL_1 = `Thrust with crashing`,
    LEVEL_2 = `Thrust with one leg`,
    LEVEL_3 = `Thrust with one leg followed by rotation of Spin 360º \n 
Thrust and vertical descent`,
    LEVEL_4 = `Thrust with one leg followed by rotation of Spin 720º or Twirl 180º \n
Thrust with flexibility`,
    LEVEL_5 = `Thrust followed by rotation of Spin 360º or Twirl 180°`,
    LEVEL_6 = `Thrust with flexibility followed by rotation of Spin 360º`,
    LEVEL_7 = `Thrust with rotation of Spin 720º and over`,
    LEVEL_8 = `Thrust with flexibility followed by rotation of Spin 720° and over`,
    LEVEL_9 = `Thrust continued by catching a sustained Vertical Position`
}
