import {NUMBER_OF_BASE_SWIMMERS} from "./NUMBER_OF_BASE_SWIMMERS";
import {DIFFICULTY_OF_COORDINATING_ACTIONS} from "./DIFFICULTY_OF_COORDINATING_ACTIONS";
import {AIRBORNE_WEIGHT} from "./AIRBORNE_WEIGHT";
import {AREA_OF_SUPPORT_FROM_WHICH_FEATURED_SWIMMER_JUMPS} from "./AREA_OF_SUPPORT_FROM_WHICH_FEATURED_SWIMMER_JUMPS";
import {AREA_OF_FULL_CONSTRUCTION} from "./AREA_OF_FULL_CONSTRUCTION";
import {SPEED_OF_ACCELERATION_AND_PUSH} from "./SPEED_OF_ACCELERATION_AND_PUSH";
import {
    FLEXIBILITY_TYPE_OF_MAINTAINED_POSITION_OF_A_SUPPORT_SWIMMER
} from "./FLEXIBILITY_TYPE_OF_MAINTAINED_POSITION_OF_A_SUPPORT_SWIMMER";
import {GROUP_AJ} from "./simple/GROUP_AJ";
import {GROUP_AW} from "./simple/GROUP_AW";
import {GROUP_BS} from "./simple/GROUP_BS";
import {GROUP_BL} from "./simple/GROUP_BL";
import {GROUP_CO} from "./simple/GROUP_CO";
import {GROUP_CT} from "./simple/GROUP_CT";
import {GROUP_CC} from "./simple/GROUP_CC";
import {GROUP_PP} from "./simple/GROUP_PP";
import {GROUP_PF} from "./simple/GROUP_PF";

export const ACROBATICS = {
    'A': {
        name: 'Airborne',
        description: 'All elements in this group are performed by a “featured-swimmer” in the air (Jumps and throws)',
        subgroups: {
            'J': {
                name: 'Jump',
                description: 'When a featured-swimmer jumps from construction. In this subgroup featured swimmer uses their legs and there’s a “repulsion phase”'
            },
            'W': {
                name: 'Throw',
                description: 'When featured-swimmer is thrown in the air by construction of the base. There’s no\n' +
                    '“repulsion phase” by feet of the featured-swimmer. For example: featured-swimmer is\n' +
                    'head-down and is pushed and thrown in the air by support-swimmer’s legs'
            }
        },
        components: [
            {
                name: 'Construction',
                elements: [
                    {
                        number: 1,
                        name: 'Throw from surface',
                        code: 'Surf',
                        numberOfLevels: 'Two levels',
                        components: [
                            NUMBER_OF_BASE_SWIMMERS.LEVEL_1,
                            DIFFICULTY_OF_COORDINATING_ACTIONS.MEDIUM,
                            AIRBORNE_WEIGHT.LEVEL_1,
                            AREA_OF_SUPPORT_FROM_WHICH_FEATURED_SWIMMER_JUMPS.BIG,
                        ]
                    },
                    {
                        number: 2,
                        name: 'Simple throw (simple jump)',
                        code: 'Thr',
                        numberOfLevels: 'Two levels',
                        components: [
                            NUMBER_OF_BASE_SWIMMERS.LEVEL_2,
                            DIFFICULTY_OF_COORDINATING_ACTIONS.LOW,
                            AIRBORNE_WEIGHT.LEVEL_1,
                            AREA_OF_FULL_CONSTRUCTION.TYPE_1,
                            SPEED_OF_ACCELERATION_AND_PUSH.FAST,
                            AREA_OF_SUPPORT_FROM_WHICH_FEATURED_SWIMMER_JUMPS.BIG,
                        ]
                    },
                    {
                        number: 3,
                        name: 'Simple throw 6-9 base swimmers',
                        code: 'Thr',
                        numberOfLevels: 'Two levels',
                        components: [
                            NUMBER_OF_BASE_SWIMMERS.LEVEL_1,
                            DIFFICULTY_OF_COORDINATING_ACTIONS.MEDIUM,
                            AIRBORNE_WEIGHT.LEVEL_1,
                            AREA_OF_FULL_CONSTRUCTION.TYPE_1,
                            SPEED_OF_ACCELERATION_AND_PUSH.FAST,
                            AREA_OF_SUPPORT_FROM_WHICH_FEATURED_SWIMMER_JUMPS.BIG,
                        ]
                    },
                    {
                        number: 4,
                        name: 'Jump from shoulders (stack type)',
                        code: 'Shou',
                        numberOfLevels: 'Three levels',
                        components: [
                            NUMBER_OF_BASE_SWIMMERS.LEVEL_1,
                            DIFFICULTY_OF_COORDINATING_ACTIONS.HARD,
                            FLEXIBILITY_TYPE_OF_MAINTAINED_POSITION_OF_A_SUPPORT_SWIMMER.LEVEL_1,
                            AIRBORNE_WEIGHT.LEVEL_2,
                            AREA_OF_FULL_CONSTRUCTION.TYPE_2,
                            SPEED_OF_ACCELERATION_AND_PUSH.MEDIUM,
                            AREA_OF_SUPPORT_FROM_WHICH_FEATURED_SWIMMER_JUMPS.MEDIUM,
                        ]
                    },
                    {
                        number: 5,

                    }
                ]
            },
            {
                name: 'Direction',
            },
            {
                name: 'Position',
            },
            {
                name: 'Plane and degree of rotation',
            },
            {
                name: 'Bonus',
            }
        ]

    },
    'B': {
        name: 'Balance',
        description: 'Acrobatic movements in this group are performed on a support/base.',
        subgroups: {
            'S': {
                name: 'Stack',
                description: 'When a featured-swimmer sits/stands or lays on “support-athlete/s” which is/are in a\n' +
                    'vertical body position (head-down or head-up)'
            },
            'L': {
                name: 'Lift',
                description: 'When featured-swimmer sits/stands or lays on “base-athletes”). Featured swimmer\n' +
                    'must be lifted up (away) from water’s surface (as high as possible), for acrobatic movement\n' +
                    'to be considered as lift'
            }
        },
        components: [
            {
                name: 'Construction',
            },
            {
                name: 'Position',
            },
            {
                name: 'Area of support and type of connect',
            },
            {
                name: 'Rotation of construction base',
            },
            {
                name: 'Bonus',
            }
        ]

    },
    'C': {
        name: 'Combined',
        description: 'Encompasses characteristics of both Airborne and Balance groups',
        subgroups: {
            'O': {
                name: 'Other',
                description: 'All those acrobatic movements that for sure are not group A or B or P, but have\n' +
                    'signs of group C but can’t be in subgroup O or subgroup T'
            },
            'T': {
                name: 'Through Support',
                description: 'Featured-swimmer jumps and passes through other formation\n' +
                    '(slight touch and continue moving) '
            },
            'C': {
                name: 'Onto Support',
                description: 'Featured-swimmer jumps from one formation onto the other formation\n' +
                    'and remains on until the submergence',
            }
        },
        components: [
            {
                name: 'Construction',
            },
            {
                name: 'Direction',
            },
            {
                name: 'Position',
            },
            {
                name: 'Rotation of construction base',
            },
            {
                name: 'Plane and degree of rotation',
            },
            {
                name: 'Bonus',
            }
        ],

    },
    'P': {
        name: 'Platform',
        description: 'The coordinated effort of team members to form a stable support on which one or more\n' +
            'swimmers is lifted to pose or perform actions. May have jump or “dismount” ending (water\n' +
            'entrance).',
        subgroups: {
            'P': {
                name: 'Standard',
                description: 'Coordinated actions of “base-swimmers”, where they lift from underwater a\n' +
                    '“support-swimmer” in horizontal position; and featured-swimmer stands, sits, or lays on\n' +
                    'support swimmer'
            },
            'F': {
                name: 'Float',
                description: 'Coordinated action of “base-swimmers” and/or “support” swimmers that form a\n' +
                    'stable geometric figure (from legs or hands or both) on the surface on which later featured\n' +
                    'swimmer execute movements. In some exceptions: floats can be lifted from underwater (it\n' +
                    'will be considered as a bonus)'
            }
        },
        components: [
            {
                name: 'Construction',
            },
            {
                name: 'Position',
            },
            {
                name: 'Area of support and type of connect',
            },
            {
                name: 'Rotation of construction base',
            },
            {
                name: 'Bonus',
            }
        ],

    },
}


export const ACROBATICS_SIMPLE = {
    'AJ': GROUP_AJ,
    'AW': GROUP_AW,
    'BS': GROUP_BS,
    'BL': GROUP_BL,
    'CO': GROUP_CO,
    'CT': GROUP_CT,
    'CC': GROUP_CC,
    'PP': GROUP_PP,
    'PF': GROUP_PF,
}
