export const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:8000';
export const authUrl = process.env.REACT_APP_AUTH_SERVER_URL || 'http://localhost:8001';
export const v1Version = 'v1'

export const calculatorScope = `${serverUrl}/api/${v1Version}/calculator`
export const utilsScope = `${serverUrl}/api/${v1Version}/utils`
export const pdfScope = `${serverUrl}/api/${v1Version}/pdf`
export const excelScope = `${serverUrl}/api/${v1Version}/excel`

export const authScope = `${authUrl}/api/${v1Version}/auth`
