import React, {useState} from "react";
import {Box, Collapse, List, ListItem, ListItemText, Paper} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarRateIcon from "@mui/icons-material/StarRate";

import {MainData} from "./PrimaryComponents/MainData";
import {Actions} from "./PrimaryComponents/Actions";
import {Translation} from "../../library/common/Translation/Translation";

const Component = () => {
    const [isMainDataOpen, setIsMainDataOpen] = useState(false);
    const [isActionsOpen, setActionsOpen] = useState(true);

    const handleMDClick = () => {
        setIsMainDataOpen((state) => !state);
    };

    const handleActionClick = () => {
        setActionsOpen((state) => !state);
    };

        return (
            <Box sx={{
                padding: 2,
                color: 'text.secondary',
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'center'
            }}>
                <Paper sx={{
                    width: '100%',
                    maxHeight: 'calc(100vh - 32px)',
                    overflowY: 'auto'
                }}>
                    <List
                        component="nav"
                        //subheader={<ListSubheader component="div">Primary Components</ListSubheader>}
                        sx={{
                            width: '100%',
                            backgroundColor: 'background.paper',
                        }}
                    >
                        <ListItem button onClick={handleMDClick} divider>
                            <StarRateIcon sx={{
                                fontSize: 20,
                                marginRight: 1,
                            }} />
                            <ListItemText primary={
                                <Translation nameSpace='common' translation={'menuGroups.mainData'}/>
                            }/>
                            {isMainDataOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItem>
                        <Collapse in={isMainDataOpen} timeout="auto" unmountOnExit >
                            <MainData />
                        </Collapse>
                        <ListItem button onClick={handleActionClick} divider>
                            <StarRateIcon sx={{
                                fontSize: 20,
                                marginRight: 1,
                            }} />
                            <ListItemText primary={
                                <Translation nameSpace='common' translation={'menuGroups.actions'}/>
                            } />
                            {isActionsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItem>
                        <Collapse in={isActionsOpen} timeout="auto" unmountOnExit >
                            <Actions />
                        </Collapse>
                    </List>
                </Paper>
            </Box>
        )

}

export const PrimaryComponents = React.memo(Component);

