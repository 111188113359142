import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEN from "../translations/en";
import translationsRU from "../translations/ru";

const resources = {
    en: translationsEN,
    ru: translationsRU,
};

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources,
    detection:{
        order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
    },
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
