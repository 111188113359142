import {
    $currentRoutineFragmentStore,
    addBonusEvent,
    addModifierEvent,
    changeAcrobaticsEvent,
    changeNumberOfMovementsEvent,
    changePairAcrobaticsEvent,
    removeBonusEvent,
    removeModifierEvent,
    removeOneBonusEvent,
    removeOneModifierEvent,
    selectTechnicalElementEvent,
    setActiveFragmentEvent
} from "./model";
import {
    IAcrobatics,
    IBonus,
    IHybrid,
    IModifier,
    IPairAcrobatics,
    IRoutineFragmentContentTypes,
    IRoutineFragment,
    ITRE
} from "../../types";
import {handleUpdateCurrentFragment} from "../routine/handlers/handleUpdateCurrentFragment";
import {NUMBER_OF_MOVEMENTS} from "../../const/descriptions";

$currentRoutineFragmentStore.on(setActiveFragmentEvent, (_state, change: IRoutineFragment<IRoutineFragmentContentTypes> | null) => {
    return change;
})

$currentRoutineFragmentStore.on(selectTechnicalElementEvent, (state, change: ITRE): IRoutineFragment<ITRE>  => ({
    number: state!.number,
    type: state!.type,
    startTime: state!.startTime,
    endTime: state!.endTime,
    content: change,
}))

$currentRoutineFragmentStore.on(changeNumberOfMovementsEvent, (state, change: NUMBER_OF_MOVEMENTS): IRoutineFragment<IHybrid>  => {
    const oldState = state as IRoutineFragment<IHybrid>
    return {
        number: oldState.number,
        type: oldState.type,
        startTime: oldState.startTime,
        endTime: oldState.endTime,
        content: {
            numberOfMovements: change,
            modifiers: oldState.content.modifiers,
            bonuses: oldState.content.bonuses,
            type: oldState.content.type
        },
    }
});


$currentRoutineFragmentStore.on(addModifierEvent, (state, change: IModifier): IRoutineFragment<IHybrid>  => {
    const oldState = state as IRoutineFragment<IHybrid>
    return {
        number: oldState.number,
        type: oldState.type,
        startTime: oldState.startTime,
        endTime: oldState.endTime,
        content: {
            numberOfMovements: oldState.content.numberOfMovements,
            modifiers: [...oldState.content.modifiers, change],
            bonuses: oldState.content.bonuses,
            type: oldState.content.type
        },
    }
});

$currentRoutineFragmentStore.on(removeModifierEvent, (state, change: IModifier): IRoutineFragment<IHybrid>  => {
    const oldState = state as IRoutineFragment<IHybrid>
    return {
        number: oldState.number,
        type: oldState.type,
        startTime: oldState.startTime,
        endTime: oldState.endTime,
        content: {
            numberOfMovements: oldState.content.numberOfMovements,
            modifiers: oldState.content.modifiers.filter((el) => el.fullName !== change.fullName),
            bonuses: oldState.content.bonuses,
            type: oldState.content.type
        },
    }
});

$currentRoutineFragmentStore.on(removeOneModifierEvent, (state, change: IModifier): IRoutineFragment<IHybrid>  => {
    const oldState = state as IRoutineFragment<IHybrid>
    const firstIndex = oldState.content.modifiers.findIndex((el) => el.fullName === change.fullName)
    if (firstIndex === -1) return oldState;
    return {
        number: oldState.number,
        type: oldState.type,
        startTime: oldState.startTime,
        endTime: oldState.endTime,
        content: {
            numberOfMovements: oldState.content.numberOfMovements,
            modifiers: [...(oldState.content.modifiers.slice(0, firstIndex)), ...oldState.content.modifiers.slice(firstIndex + 1)],
            bonuses: oldState.content.bonuses,
            type: oldState.content.type
        },
    }
})

$currentRoutineFragmentStore.on(addBonusEvent, (state, change: IBonus): IRoutineFragment<IHybrid>  => {
    const oldState = state as IRoutineFragment<IHybrid>
    return {
        number: oldState.number,
        type: oldState.type,
        startTime: oldState.startTime,
        endTime: oldState.endTime,
        content: {
            numberOfMovements: oldState.content.numberOfMovements,
            modifiers: oldState.content.modifiers,
            bonuses: [...oldState.content.bonuses, change],
            type: oldState.content.type
        },
    }
});

$currentRoutineFragmentStore.on(removeBonusEvent, (state, change: IBonus): IRoutineFragment<IHybrid>  => {
    const oldState = state as IRoutineFragment<IHybrid>
    return {
        number: oldState.number,
        type: oldState.type,
        startTime: oldState.startTime,
        endTime: oldState.endTime,
        content: {
            numberOfMovements: oldState.content.numberOfMovements,
            bonuses: oldState.content.bonuses.filter((el) => el.fullName !== change.fullName),
            modifiers: oldState.content.modifiers,
            type: oldState.content.type
        },
    }
})
$currentRoutineFragmentStore.on(removeOneBonusEvent, (state, change: IBonus): IRoutineFragment<IHybrid>  => {
    const oldState = state as IRoutineFragment<IHybrid>
    const firstIndex = oldState.content.modifiers.findIndex((el) => el.fullName === change.fullName)
    if (firstIndex === -1) return oldState;
    return {
        number: oldState.number,
        type: oldState.type,
        startTime: oldState.startTime,
        endTime: oldState.endTime,
        content: {
            numberOfMovements: oldState.content.numberOfMovements,
            bonuses: [...(oldState.content.bonuses.slice(0, firstIndex)), ...oldState.content.bonuses.slice(firstIndex + 1)],
            modifiers: oldState.content.modifiers,
            type: oldState.content.type
        },
    }
})

$currentRoutineFragmentStore.on(changeAcrobaticsEvent, (state, change: IAcrobatics): IRoutineFragment<IAcrobatics> => {
    const oldState = state as IRoutineFragment<IAcrobatics>;
    return {
        number: oldState.number,
        type: oldState.type,
        startTime: oldState.startTime,
        endTime: oldState.endTime,
        content: change,
    }
})

$currentRoutineFragmentStore.on(changePairAcrobaticsEvent, (state, change: IPairAcrobatics): IRoutineFragment<IPairAcrobatics> => {
    const oldState = state as IRoutineFragment<IPairAcrobatics>;
    return {
        number: oldState.number,
        type: oldState.type,
        startTime: oldState.startTime,
        endTime: oldState.endTime,
        content: change,
    }
})

$currentRoutineFragmentStore.watch((state) => {
    handleUpdateCurrentFragment(state!);
})
