import {IRoutine} from "../../../types";
import {IApiResponse} from "../../../types/http";
import {postGetCanadianXLSX} from "../../../http/excel";

export const getCanadianXLSX = async (routine: IRoutine) => {
    const response = await fetch(postGetCanadianXLSX.url, {
        method: postGetCanadianXLSX.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(routine),
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.data
}
