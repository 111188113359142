import React, {useState} from "react";
import {Button, Dialog, ListItem} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {SelectTechnicalElements} from "../SelectTechnicalElements";
import {Translation} from "../../../../library/common/Translation/Translation";

const Component = () => {
    const [isSelectTEOpen, setIsSelectTEOpen] = useState(false)

    const openSelectTEDialog = () => {
        setIsSelectTEOpen(true);
    }
    const closeSelectTEDialog = () => {
        setIsSelectTEOpen(false);
    }

    return (
        <React.Fragment>
            <ListItem>
                <Button
                    onClick={openSelectTEDialog}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    sx={{
                        margin: 1,
                    }}
                >
                    <ControlPointIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.selectTRE'}/>
                </Button>
                <Dialog onClose={closeSelectTEDialog} open={isSelectTEOpen}>
                    <SelectTechnicalElements onOk={closeSelectTEDialog}/>
                </Dialog>
            </ListItem>
        </React.Fragment>
    )
}

export const TREActions = React.memo(Component)
