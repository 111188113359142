// @ts-ignore
import React, {useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import {IAcrobaticsComponent, IPairAcrobatics, IRoutineFragment} from "../../../types";
import SaveIcon from "@mui/icons-material/Save";
import {$currentRoutineFragmentStore} from "../../../stores";
import {useStore} from "effector-react";
import {PAIR_ACROBATICS} from "../../../const/pairAcrobatics";
import {handlePairAcrobaticsChange} from "../../../stores/currentRoutineFragment/handlers/handlePairAcrobaticsChange";

type IProps = {
    onOk: ()=>void
}

const Component = (props: IProps) => {
    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<IPairAcrobatics>
    const [pairAcrobatics, setPairAcrobatics] = useState<IPairAcrobatics>({
        level: currentRoutineFragment.content.level || '',
        component: {
            name: currentRoutineFragment.content.component?.name || '',
            code: currentRoutineFragment.content.component?.code || '',
            value: currentRoutineFragment.content.component?.value || 0,
        },
    });

    const handleLevelChange = (e: SelectChangeEvent) => {
        setPairAcrobatics((state) => {
            const newState = {...state};
            newState.level = e.target.value;
            return newState;
        })
    }

    const handleChange = (e: SelectChangeEvent) => {
        const selectedLevel = PAIR_ACROBATICS[pairAcrobatics.level as keyof typeof PAIR_ACROBATICS];
        if (!selectedLevel) return;
        const selectedElement = selectedLevel.find((item) => item.code === e.target.value)! as IAcrobaticsComponent
        setPairAcrobatics((state) => {
            const newState = {...state};
            newState.component = selectedElement;
            return newState;
        });
    }

    const savePairAcrobatics = () => {
        handlePairAcrobaticsChange(pairAcrobatics)
        props.onOk();
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            gap: 2,
            width: '30vw',
        }}>
            <FormControl fullWidth>
                <InputLabel id="pair-acrobatics-level-label">Level</InputLabel>
                <Select
                    labelId="pair-acrobatics-level-label"
                    id="pair-acrobatics-level-select"
                    value={pairAcrobatics.level}
                    label="Level"
                    onChange={handleLevelChange}
                    sx={{textTransform: 'capitalize'}}
                >
                    {Object.keys(PAIR_ACROBATICS).map((item, index) =>
                        <MenuItem sx={{textTransform: 'capitalize'}} value={`LEVEL_${index + 1}`}>
                            {item.split('_').join(" ").toLowerCase()}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            {PAIR_ACROBATICS[pairAcrobatics.level as keyof typeof PAIR_ACROBATICS] &&
                <FormControl fullWidth>
                    <InputLabel id="pair-acrobatics-label">Movement</InputLabel>
                    <Select
                        defaultValue=''
                        labelId="pair-acrobatics-label"
                        id="pair-acrobatics-select"
                        value={currentRoutineFragment.content.component?.code}
                        label="Movement"
                        name="movement"
                        onChange={handleChange}
                    >
                        {PAIR_ACROBATICS[pairAcrobatics.level as keyof typeof PAIR_ACROBATICS]!.map((item) =>
                            <MenuItem value={item.code}>
                                {item.code} - {item.name} - {item.value}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            <Button
                variant="contained"
                color="primary"
                fullWidth
                size="small"
                sx={{
                    margin: 1,
                }}
                onClick={savePairAcrobatics}
            >
                <SaveIcon
                    sx={{
                        marginRight: 1,
                        fontSize: 20,
                    }}
                />
                Save pair acrobatics
            </Button>
        </Box>
    )
}

export const SelectPairAcrobatics = React.memo(Component);
