import React, {useEffect, useState} from "react";
import {useStore} from "effector-react";
import {Box, Card, CardActionArea, CardContent, Typography} from "@mui/material";

import {FRAGMENT_COLORS} from "../../../const/FRAGMENT_COLORS";
import {
    IAcrobatics,
    IHybrid,
    IPairAcrobatics,
    IRoutineFragment,
    IRoutineFragmentContentTypes,
    ITRE
} from "../../../types";
import {$currentRoutineFragmentStore, $routineStore} from "../../../stores";
import {handleSetActiveFragment} from "../../../stores/currentRoutineFragment/handlers/handleSetActiveFragment";
import {ACTIVE_FRAGMENT_COLORS} from "../../../const/ACTIVE_FRAGMENT_COLORS";
import {formatTime} from "../../../services/formatTime";
import {ROUTINE_FRAGMENT_TYPES} from "../../../const/ROUTINE_FRAGMENT_TYPES";
import {
    calculateAcrobatics,
    calculateHybrid,
    calculatePairAcrobatics,
    calculateTRE
} from "../../../services/apiRequests/calculator";
import {ROUTINE_TYPES} from "../../../const/ROUTINE_TYPES";

type IProps = {
    data: IRoutineFragment<IRoutineFragmentContentTypes>
}

const Component = (props: IProps) => {
    const currentRoutineFragmentStore = useStore($currentRoutineFragmentStore);
    const routine = useStore($routineStore);
    const isActive = currentRoutineFragmentStore?.number === props.data.number;
    const fragmentType = routine.type !== ROUTINE_TYPES.FREE_COMBO || props.data.type !== ROUTINE_FRAGMENT_TYPES.HYBRID
        ? props.data.type
        : ((props.data.content as IHybrid)?.type || '') + '-HYBRID'
    const [difficulty, setDifficulty] = useState(0);
    useEffect(() => {
        const calculateFragmentDifficulty = async () => {
            switch (props.data.type) {
                case ROUTINE_FRAGMENT_TYPES.ACROBATICS: {
                    setDifficulty(await calculateAcrobatics(props.data as IRoutineFragment<IAcrobatics>))
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.PAIR_ACROBATICS:{
                    setDifficulty(await calculatePairAcrobatics(props.data as IRoutineFragment<IPairAcrobatics>))
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.HYBRID: {
                    setDifficulty(await calculateHybrid(props.data as IRoutineFragment<IHybrid>))
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.TRE: {
                    setDifficulty(await calculateTRE(props.data as IRoutineFragment<ITRE>))
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.START: {
                    setDifficulty(0)
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.TRANSITION: {
                    setDifficulty(0)
                    break;
                }
                default: {
                    setDifficulty(0)
                    break;
                }
            }
        }
        calculateFragmentDifficulty().then();
    }, [props.data, routine.type])

    const setActive = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        isActive ? handleSetActiveFragment(null) : handleSetActiveFragment(props.data);
    }

    return (
        <Card raised
              onClick={setActive}
              tabIndex={props.data.number}
              sx={{
                  minWidth: '24ch',
                  margin: 1,
                  background: isActive ? ACTIVE_FRAGMENT_COLORS[props.data.type] : FRAGMENT_COLORS[props.data.type],
                  boxShadow: isActive ? `0px 0px 0px 5px ${ACTIVE_FRAGMENT_COLORS[props.data.type]} inset` : 'none',
              }}
        >
            <CardActionArea>
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <Typography align="center" color="textSecondary">
                            Fragment №{props.data.number}
                        </Typography>
                        <Typography align="center" color="textSecondary">
                            {difficulty}
                        </Typography>
                    </Box>
                    <Typography align="center" variant="h5" component="h2" textTransform='uppercase'>
                        {fragmentType}
                    </Typography>
                    <Typography align="center" color="textSecondary">
                        {`${formatTime(props.data.startTime)} - ${formatTime(props.data.endTime)}`}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export const Fragment = React.memo(Component)
