export const LEVEL_1 = [
    {
        name: 'Lift head-up with crashing',
        code: 'L>>',
        value: 0.10
    }, {
        name: 'Lift legs-up with crashing',
        code: 'L!>>',
        value: 0.20
    }
]

export const LEVEL_2 = [
    {
        name: 'Lift head-up',
        code: 'L',
        value: 0.40
    }, {
        name: 'Lift head-up with flexibility and crashing',
        code: 'Lf>>',
        value: 0.40
    }, {
        name: 'Lift legs-up with flexibility and crashing',
        code: 'L!f>>',
        value: 0.40
    }, {
        name: 'Lift legs-up with crashing and rotation 180°',
        code: 'L!r0,5>>',
        value: 0.60
    }, {
        name: 'Lift legs-up',
        code: 'L!',
        value: 0.60
    }
]

export const LEVEL_3 = [
    {
        name: 'Lift legs-up with crashing and rotation 360°',
        code: 'L!r>>',
        value: 0.60
    }, {
        name: 'Lift legs-up with crashing, flexibility and rotation (turn) 180°',
        code: 'L!fr0,5>>',
        value: 0.60
    }, {
        name: 'Lift head-up with rotation 180°',
        code: 'Lr0,5',
        value: 0.60
    }, {
        name: 'Sustained lift head-up with travelling',
        code: 'SL>',
        value: 0.80
    }
]

export const LEVEL_4 = [
    {
        name: 'Lift legs-up with rotation 180°',
        code: 'L!r0,5',
        value: 0.80
    }, {
        name: 'Lift head-up with flexibility and rotation 180°',
        code: 'Lfr0,5',
        value: 0.80
    }, {
        name: 'Lift legs-up with flexibility',
        code: 'L!f',
        value: 0.80
    }, {
        name: 'Sustained lift legs-up with travelling',
        code: 'SL!>',
        value: 0.80
    }, {
        name: 'Lift head-up with rotation 360°',
        code: 'Lr1',
        value: 0.80
    }, {
        name: 'Jump head-up',
        code: 'J',
        value: 0.80
    }, {
        name: 'Throw legs-up with crashing',
        code: 'W!>>',
        value: 0.80
    }
]

export const LEVEL_5 = [
    {
        name: 'Lift legs-up with rotation 360°',
        code: 'L!r1',
        value: 1.00
    }, {
        name: 'Lift legs-up with flexibility and rotation 180°',
        code: 'L!fr0,5',
        value: 1.00
    }, {
        name: 'Sustained lift legs-up with flexibility and travelling',
        code: 'SL!f>',
        value: 1.00
    }, {
        name: 'Sustained lift legs-up with travelling and rotation 180°',
        code: 'SL!>r0,5',
        value: 1.00
    }, {
        name: 'Sustained lift legs-up with travelling and rotation 360°',
        code: 'SL!>r1',
        value: 1.00
    }, {
        name: 'Jump head-up with rotation 180°',
        code: 'Jr0,5',
        value: 1.00
    }, {
        name: 'Jump head-up with flexibility',
        code: 'Jf',
        value: 1.00
    }, {
        name: 'Legs-up throw-dive',
        code: 'T!D',
        value: 1.00
    }
]

export const LEVEL_6 = [
    {
        name: 'Lift legs-up with flexibility and rotation 360°',
        code: 'L!fr1',
        value: 1.20
    }, {
        name: 'Sustained lift legs-up with flexibility, travelling and rotation 180°',
        code: 'SL!fr0,5>',
        value: 1.20
    }, {
        name: 'Sustained lift legs-up with flexibility, travelling and rotation 360°',
        code: 'SL!fr1>',
        value: 1.20
    }, {
        name: 'Throw legs-up with rotation 180°',
        code: 'W!r0,5',
        value: 1.20
    }, {
        name: 'Throw legs-up with flexibility',
        code: 'W!f',
        value: 1.20
    }, {
        name: 'Jump-Dive',
        code: 'Jd',
        value: 1.20
    }
]

export const LEVEL_7 = [
    {
        name: 'Throw legs-up with somersault 180°',
        code: 'W!s0,5',
        value: 1.40
    }, {
        name: 'Throw legs-up with flexibility and rotation 180°',
        code: 'W!fr0,5',
        value: 1.40
    }
]

export const LEVEL_8 = [
    {
        name: 'Throw legs-up with flexibility and rotation 360°',
        code: 'W!fr1',
        value: 1.60
    }, {
        name: 'Jump head-up with 1 somersault forwards',
        code: 'Js1F',
        value: 2.00
    }, {
        name: 'Jump head-up with 1 somersault backwards and flexibility',
        code: 'Jfs1B',
        value: 2.00
    }
]

export const LEVEL_9 = [
    {
        name: 'Throw legs-up with 1 somersault forwards',
        code: 'W!s1F',
        value: 2.20
    }
]

export const PAIR_ACROBATICS = {
    LEVEL_1,
    LEVEL_2,
    LEVEL_3,
    LEVEL_4,
    LEVEL_5,
    LEVEL_6,
    LEVEL_7,
    LEVEL_8,
    LEVEL_9
}
