import React, {useState} from "react";
import {Button, Dialog, ListItem} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {SelectAcrobatics} from "../SelectAcrobatics";
import {Translation} from "../../../../library/common/Translation/Translation";

const Component = () => {
    const [isChangeAcrobaticsOpen, setIsChangeAcrobaticsDialogOpen] = useState(false)

    const openAcrobaticsDialog = () => {
        setIsChangeAcrobaticsDialogOpen(true);
    }
    const closeAcrobaticsDialog = () => {
        setIsChangeAcrobaticsDialogOpen(false);
    }


    return (
        <React.Fragment>
            <ListItem>
                <Button
                    onClick={openAcrobaticsDialog}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    sx={{
                        margin: 1,
                    }}
                >
                    <ControlPointIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.changeAcrobatics'}/>
                </Button>
                <Dialog onClose={closeAcrobaticsDialog} open={isChangeAcrobaticsOpen}>
                    <SelectAcrobatics onOk={closeAcrobaticsDialog}/>
                </Dialog>
            </ListItem>
        </React.Fragment>
    )
}

export const AcrobaticsActions = React.memo(Component)
