import React, {useState} from "react";
import {Button, Dialog, ListItem} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import {SelectModifiers} from "../SelectModifiers";
import {SelectBonuses} from "../SelectBonuses";
// import {SelectNumberOfMovements} from "../SelectNumberOfMovements";
import {Translation} from "../../../../library/common/Translation/Translation";

const Component = () => {
    const [isChangeModifierDialogOpen, setIsChangeModifierDialogOpen] = useState(false)
    const [isChangeBonusDialogOpen, setIsChangeBonusDialogOpen] = useState(false)
    // const [isChangeNumberOfMovementsDialogOpen, setIsChangeNumberOfMovementsDialogOpen] = useState(false)
    const openModifierDialog = () => {
        setIsChangeModifierDialogOpen(true);
    }
    const closeModifierDialog = () => {
        setIsChangeModifierDialogOpen(false);
    }
    const openBonusDialog = () => {
        setIsChangeBonusDialogOpen(true);
    }
    const closeBonusDialog = () => {
        setIsChangeBonusDialogOpen(false);
    }

    // const openNumberOfMovementsDialog = () => {
    //     setIsChangeNumberOfMovementsDialogOpen(true);
    // }
    // const closeNumberOfMovementsDialog = () => {
    //     setIsChangeNumberOfMovementsDialogOpen(false);
    // }

    return (
        <React.Fragment>
            {/*<ListItem>*/}
            {/*    <Button*/}
            {/*        onClick={openNumberOfMovementsDialog}*/}
            {/*        variant="contained"*/}
            {/*        color="primary"*/}
            {/*        fullWidth*/}
            {/*        size="small"*/}
            {/*        sx={{*/}
            {/*            margin: 1,*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <ControlPointIcon*/}
            {/*            sx={{*/}
            {/*                marginRight: 1,*/}
            {/*                fontSize: 20,*/}
            {/*            }}*/}
            {/*        />*/}
            {/*        <Translation nameSpace='common' translation={'buttons.changeNM'}/>*/}
            {/*    </Button>*/}
            {/*    <Dialog onClose={closeNumberOfMovementsDialog}*/}
            {/*            open={isChangeNumberOfMovementsDialogOpen}>*/}
            {/*        <SelectNumberOfMovements onOk={closeNumberOfMovementsDialog}/>*/}
            {/*    </Dialog>*/}
            {/*</ListItem>*/}
            <ListItem>
                <Button
                    onClick={openModifierDialog}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    sx={{
                        margin: 1,
                    }}
                >
                    <ControlPointIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.changeModifiers'}/>
                </Button>
                <Dialog maxWidth={false} onClose={closeModifierDialog}
                        open={isChangeModifierDialogOpen}>
                    <SelectModifiers/>
                </Dialog>
            </ListItem>
            <ListItem>
                <Button
                    onClick={openBonusDialog}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    sx={{
                        margin: 1,
                    }}
                >
                    <ControlPointIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.changeBonus'}/>
                </Button>
                <Dialog maxWidth={false} onClose={closeBonusDialog} open={isChangeBonusDialogOpen}>
                    <SelectBonuses/>
                </Dialog>
            </ListItem>
        </React.Fragment>
    )
}

export const HybridActions = React.memo(Component)
