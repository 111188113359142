import {TRE_VARIANTS} from "./TRE_VARIANTS";
import {ROUTINE_TYPES} from "../ROUTINE_TYPES";

export const SOLO_TECHNICAL_ELEMENTS = {
    TRE1a: {
        routineType: ROUTINE_TYPES.TECH_SOLO,
        TRENumber: 1,
        TREVariant: TRE_VARIANTS.A,
        description: 'Thrust Continuous Spin 720°',
    },
    TRE1b: {
        routineType: ROUTINE_TYPES.TECH_SOLO,
        TRENumber: 1,
        TREVariant: TRE_VARIANTS.B,
        description: 'Thrust Spinning 360°',
    },
    TRE2a: {
        routineType: ROUTINE_TYPES.TECH_SOLO,
        TRENumber: 2,
        TREVariant: TRE_VARIANTS.A,
        description: 'Combined Spin 1080° – Continuous Spin 1080°',
    },
    TRE2b: {
        routineType: ROUTINE_TYPES.TECH_SOLO,
        TRENumber: 2,
        TREVariant: TRE_VARIANTS.B,
        description: 'Combined Spin 720° – Continuous Spin 1080°',
    },
    TRE3: {
        routineType: ROUTINE_TYPES.TECH_SOLO,
        TRENumber: 3,
        TREVariant: TRE_VARIANTS.NONE,
        description: 'Swordfish Straight Leg - Knight',
    },
    TRE4a: {
        routineType: ROUTINE_TYPES.TECH_SOLO,
        TRENumber: 4,
        TREVariant: TRE_VARIANTS.A,
        description: 'Fishtail Half Twist - Continuous Spin 720°',
    },
    TRE4b: {
        routineType: ROUTINE_TYPES.TECH_SOLO,
        TRENumber: 4,
        TREVariant: TRE_VARIANTS.B,
        description: 'Fishtail - Continuous Spin 720°',
    },
    TRE5a: {
        routineType: ROUTINE_TYPES.TECH_SOLO,
        TRENumber: 5,
        TREVariant: TRE_VARIANTS.A,
        description: 'Rocket Split Bent Knee Joining 360°',
    },
    TRE5b: {
        routineType: ROUTINE_TYPES.TECH_SOLO,
        TRENumber: 5,
        TREVariant: TRE_VARIANTS.B,
        description: 'Rocket Split Bent Knee',
    }
}
