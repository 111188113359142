import React, {useState} from "react";
import {Box, Collapse, List, ListItem, ListItemText, ListSubheader, Paper, Typography} from "@mui/material";
import {useStore} from "effector-react";
import ListIcon from "@mui/icons-material/List";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarRateIcon from "@mui/icons-material/StarRate";


import {MainInformation} from "./SecondaryComponents/MainInformation";
import {FragmentContent} from "./SecondaryComponents/FragmentContent";
import {$currentRoutineFragmentStore} from "../../stores";
import {Actions} from "./SecondaryComponents/Actions";
import {Translation} from "../../library/common/Translation/Translation";

const Component = () => {
    const currentRoutineFragmentStore = useStore($currentRoutineFragmentStore);
    const [isMainInformationOpen, setIsMainInformationOpen] = useState(false);
    const [isModifiersOpen, setIsModifiersOpen] = useState(false);
    const [isActionsOpen, setIsActionsOpen] = useState(true);

    const handleMIClick = () => {
        setIsMainInformationOpen(state => !state);
    };

    const handleModifiersClick = () => {
        setIsModifiersOpen(state => !state);
    };

    const handleActionsClick = () => {
        setIsActionsOpen(state => !state);
    };

    return (
        <Box sx={{
            padding: 2,
            color: 'text.secondary',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'center'
        }}>
            <Paper sx={{
                width: '100%',
                maxHeight: 'calc(100vh - 32px)',
                overflowY: 'auto'
            }}>
                <List
                    component="nav"
                    subheader={
                        <ListSubheader component="h3">
                            <Typography variant='h5'
                                        textTransform={'uppercase'}>{currentRoutineFragmentStore?.type}</Typography>
                        </ListSubheader>
                    }
                    sx={{
                        width: '100%',
                        backgroundColor: 'background.paper'
                    }}
                >
                    <ListItem button onClick={handleMIClick} divider>
                        <StarRateIcon sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}/>
                        <ListItemText primary={
                            <Translation nameSpace='common' translation={'menuGroups.mainData'}/>
                        }/>
                        {isMainInformationOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </ListItem>
                    <Collapse in={isMainInformationOpen} timeout="auto" unmountOnExit>
                        <MainInformation/>
                    </Collapse>

                    <ListItem button onClick={handleModifiersClick} divider>
                        <ListIcon sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}/>
                        <ListItemText primary={
                            <Translation nameSpace='common' translation={'menuGroups.content'}/>
                        }/>
                        {isModifiersOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </ListItem>
                    <Collapse in={isModifiersOpen} timeout="auto" unmountOnExit>
                        <FragmentContent/>
                    </Collapse>

                    <ListItem button onClick={handleActionsClick} divider>
                        <CallToActionIcon
                            sx={{
                                marginRight: 1,
                                fontSize: 20,
                            }}/>
                        <ListItemText primary={
                            <Translation nameSpace='common' translation={'menuGroups.actions'}/>
                        }/>
                        {isActionsOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </ListItem>
                    <Collapse in={isActionsOpen} timeout="auto" unmountOnExit>
                        <Actions/>
                    </Collapse>
                </List>
            </Paper>
        </Box>
    )
}


export const SecondaryComponents = React.memo(Component)
