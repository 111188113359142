import React from "react";
import {useStore} from "effector-react";
import {$currentRoutineFragmentStore} from "../../../../stores";
import {Box, Grid, List, ListItem, ListItemText, ListSubheader} from "@mui/material";
import PoolIcon from "@mui/icons-material/Pool";
import {Translation} from "../../../../library/common/Translation/Translation";
import {IHybrid, IRoutineFragment} from "../../../../types";

const Component = () => {
    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<IHybrid>;

    return (
        <Grid container alignItems={"stretch"} justifyContent={"center"} sx={{
            padding: 2,
            color: 'text.secondary',
        }}>
            <Box sx={{
                width: "100%"
            }}>
                <List
                    component="nav"
                    subheader={
                        <ListSubheader component="div">
                            <Translation nameSpace='common' translation="domain.modifiers"/>
                        </ListSubheader>
                    }
                    sx={{
                        width: '100%',
                        backgroundColor: 'background.paper',
                    }}
                >
                    {currentRoutineFragment.content.modifiers.map(item => {
                        return (
                            <ListItem>
                                <PoolIcon
                                    sx={{
                                        marginRight: 1,
                                        fontSize: 20,
                                    }}
                                />
                                <ListItemText primary={`${item.fullName}${item.multiplier ? `*${item.multiplier}` : ''}`}/>
                            </ListItem>
                        )
                    })}
                </List>
                <List
                    component="nav"
                    subheader={
                        <ListSubheader component="div">
                            <Translation nameSpace='common' translation="domain.bonuses"/>
                        </ListSubheader>
                    }
                    sx={{
                        width: '100%',
                        backgroundColor: 'background.paper',
                    }}
                >
                    {currentRoutineFragment.content.bonuses.map(item => {
                        return (
                            <ListItem>
                                <PoolIcon
                                    sx={{
                                        marginRight: 1,
                                        fontSize: 20,
                                    }}
                                />
                                <ListItemText primary={`${item.code}${item.multiplier ? `*${item.multiplier}` : ''}`}/>
                            </ListItem>
                        )
                    })}
                </List>
            </Box>
        </Grid>
    )
}

export const HybridContent = React.memo(Component)
