import {IAcrobatics, IRoutineFragment} from "../../../types";
import {postCalculateAcrobatics} from "../../../http/calculator";
import {IApiResponse} from "../../../types/http";

export const calculateAcrobatics = async (acrobatics: IRoutineFragment<IAcrobatics>) => {
    const response = await fetch(postCalculateAcrobatics.url, {
        method: postCalculateAcrobatics.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(acrobatics),
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.difficulty
}
