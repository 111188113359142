import React from "react";
import {useStore} from "effector-react";
import {$currentRoutineFragmentStore} from "../../../stores";
import {ROUTINE_FRAGMENT_TYPES} from "../../../const/ROUTINE_FRAGMENT_TYPES";
import {IRoutineFragment, IRoutineFragmentContentTypes} from "../../../types";
import {HybridContent} from "./FragmentContent/HybridContent";
import {PairAcrobaticsContent} from "./FragmentContent/PairAcrobaticsContent";
import {AcrobaticsContent} from "./FragmentContent/AcrobaticsContent";
import {TREContent} from "./FragmentContent/TREContent";

const Component = () => {
    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<IRoutineFragmentContentTypes>;
    switch (currentRoutineFragment.type) {
        case ROUTINE_FRAGMENT_TYPES.HYBRID:
            return <HybridContent />

        case ROUTINE_FRAGMENT_TYPES.PAIR_ACROBATICS:
            return <PairAcrobaticsContent/>

        case ROUTINE_FRAGMENT_TYPES.ACROBATICS:
            return <AcrobaticsContent/>

        case ROUTINE_FRAGMENT_TYPES.TRE:
            return <TREContent/>

        case ROUTINE_FRAGMENT_TYPES.TRANSITION:
            return null;

        case ROUTINE_FRAGMENT_TYPES.START:
            return null;

        default:
            return null;
    }
}

export const FragmentContent = React.memo(Component)
