import React from "react";
import {Button, List, ListItem} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PrintIcon from "@mui/icons-material/Print";

import {handleChangeMedia} from "../../../stores/media/handlers/handleChangeMedia";
import {useStore} from "effector-react";
import {$routineStore} from "../../../stores";
import {encryptRoutine} from "../../../services/apiRequests/calculator";
import {decryptRoutine} from "../../../services/apiRequests/calculator";
import {IRoutine} from "../../../types";
import {handleLoadRoutine} from "../../../stores/routine/handlers/handleLoadRoutine";
import {getCoachCard} from "../../../services/apiRequests/calculator";
import {Translation} from "../../../library/common/Translation/Translation";
import {useTranslation} from "../../../hooks/useTranslation";
import {getCanadianXLSX} from "../../../services/apiRequests/calculator/getCanadianXLSX";

const Component = () => {
    const routine = useStore($routineStore);
    const {translation} = useTranslation('common');

    const uploadVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0]
        if (!file) return;
        const src = URL.createObjectURL(file)
        const type = file.type.split('/')[0] === 'audio' ? 'audio' : 'video'
        handleChangeMedia({type, src})
    }

    const uploadRoutineFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files![0]) return;
        const fr = new FileReader()
        fr.readAsArrayBuffer(e.target!.files![0]);
        fr.onload = async () => {
            const data = await decryptRoutine(fr.result as ArrayBuffer) as IRoutine;
            handleLoadRoutine(data);
        }
    }

    const getRoutineFile = async () => {
        const data = await encryptRoutine(routine);
        const bytes = new Uint8Array(data.data);
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(
            new Blob([bytes])
        );
        link.href = url;
        link.setAttribute(
            'download',
            `${routine.description.team}-${translation(`routineTypes.${routine.type}`)}.asr`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        link.parentNode?.removeChild(link);
    }

    const downloadCoachCard = async () => {
        const data = await getCoachCard(routine);
        const bytes = new Uint8Array(data.data);
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(
            new Blob([bytes])
        );
        link.href = url;
        link.setAttribute(
            'download',
            `${routine.description.team}.pdf`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        link.parentNode?.removeChild(link);
    }

    const downloadCanadianXLSX = async () => {
        const data = await getCanadianXLSX(routine);
        const bytes = new Uint8Array(data.data);
        const link = document.createElement('a');
        let blob = new Blob([bytes], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute(
            'download',
            `${routine.description.team}.xlsx`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        link.parentNode?.removeChild(link);
    }

    return (
        <List>
            <ListItem>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    component="label"
                    sx={{
                        margin: 1,
                    }}
                >
                    <input onChange={uploadRoutineFile} hidden accept=".asr" type="file"/>
                    <CloudUploadIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.uploadASR'}/>
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    sx={{
                        margin: 1,
                    }}
                    onClick={getRoutineFile}
                >
                    <CloudDownloadIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.downloadASR'}/>
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    component="label"
                    sx={{
                        margin: 1,
                    }}
                >
                    <input onChange={uploadVideo} hidden accept="video/*, audio/*" type="file"/>
                    <CloudUploadIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.uploadMedia'}/>
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    component="label"
                    sx={{
                        margin: 1,
                    }}
                    onClick={downloadCoachCard}
                >
                    <PrintIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.downloadCoachCard'}/>
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    component="label"
                    sx={{
                        margin: 1,
                    }}
                    onClick={downloadCanadianXLSX}
                >
                    <PrintIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.downloadCanadianXLSX'}/>
                </Button>
            </ListItem>
        </List>
    )
}

export const Actions = React.memo(Component)
