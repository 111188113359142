import React, {useRef, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardActionArea,
    CardContent,
    IconButton,
    Popover,
    Tooltip,
    Typography
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {useStore} from "effector-react";

import {IBonus, IHybrid, IRoutineFragment} from "../../../types";
import {ACTIVE_FRAGMENT_COLORS} from "../../../const/ACTIVE_FRAGMENT_COLORS";
import {$currentRoutineFragmentStore} from "../../../stores";
import {handleRemoveBonus} from "../../../stores/currentRoutineFragment/handlers/removeBonus";
import {handleAddBonus} from "../../../stores/currentRoutineFragment/handlers/addBonus";
import {handleRemoveOneBonus} from "../../../stores/currentRoutineFragment/handlers/removeOneBonus";
import {ROUTINE_FRAGMENT_TYPES} from "../../../const/ROUTINE_FRAGMENT_TYPES";

type IProps = {
    data: IBonus
}

const Component = (props: IProps) => {
    const cardRef = useRef(null);
    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<IHybrid>
    const [multiplierAnchorEl, setMultiplierAnchorEl] = useState<HTMLDivElement | null>(null);
    const isMultiplierPopoverOpen = Boolean(multiplierAnchorEl);
    const isActive = currentRoutineFragment.content.bonuses.some((el) => el.fullName === props.data.fullName);
    const count = currentRoutineFragment.content.bonuses.filter((el) => el.fullName === props.data.fullName).length;

    const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (!isActive) {
            openMultiplierPopover()
        } else {
            removeAllBonuses()
        }
    }

    const removeAllBonuses = (event?: React.MouseEvent<HTMLDivElement>) => {
        if (event) event.stopPropagation();
        handleRemoveBonus(props.data)
    }

    const openMultiplierPopover = (event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        if (event) event.stopPropagation();
        setMultiplierAnchorEl(cardRef.current);
    };

    const closeMultiplierPopover = () => {
        setMultiplierAnchorEl(null);
    };

    const addBonus = (multiplier?: 0.3 | 0.5) => {
        if (multiplier) {
            const bonus = {...props.data, multiplier}
            handleAddBonus(bonus)
        } else {
            handleAddBonus(props.data)
        }
        closeMultiplierPopover();
    }

    const handleMinus = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        handleRemoveOneBonus(props.data)
    }

    return (
        <React.Fragment>
            <Card
                ref={cardRef}
                raised
                onClick={handleCardClick}
                sx={{
                    margin: 1,
                    background: isActive ? ACTIVE_FRAGMENT_COLORS[ROUTINE_FRAGMENT_TYPES.HYBRID] : 'white',
                    maxWidth: '100%'
                }}
            >
                <CardActionArea>
                    <CardContent>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Tooltip title={props.data.description}>
                                <InfoOutlinedIcon/>
                            </Tooltip>
                            {isActive ?
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <IconButton sx={{padding: 0}} onClick={handleMinus} disabled={count <= 1}>
                                        <ArrowLeftIcon/>
                                    </IconButton>
                                    <Typography sx={{height: '24px'}}>{count}</Typography>
                                    <IconButton sx={{padding: 0}} onClick={openMultiplierPopover}>
                                        <ArrowRightIcon/>
                                    </IconButton>
                                </Box>
                                : null}
                        </Box>
                        <Typography align="center" variant="h5" component="h2" textTransform='uppercase'>
                            {props.data.code}
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: 4
                        }}>
                            <Typography align="center" color="textSecondary">
                                {props.data.fullName}
                            </Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Popover
                anchorEl={multiplierAnchorEl}
                open={isMultiplierPopoverOpen}
                onClose={closeMultiplierPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <ButtonGroup>
                    <Button onClick={() => addBonus(0.3)}>Less than half</Button>
                    <Button onClick={() => addBonus(0.5)}>Half and more</Button>
                    <Button onClick={() => addBonus()}>All</Button>
                </ButtonGroup>
            </Popover>
        </React.Fragment>
    )
}

export const Bonus = React.memo(Component)
