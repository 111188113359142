import {IPairAcrobatics, IRoutineFragment} from "../../../types";
import {postCalculatePairAcrobatics} from "../../../http/calculator";
import {IApiResponse} from "../../../types/http";

export const calculatePairAcrobatics = async (pairAcrobatics: IRoutineFragment<IPairAcrobatics>) => {
    const response = await fetch(postCalculatePairAcrobatics.url, {
        method: postCalculatePairAcrobatics.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pairAcrobatics),
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.difficulty
}
