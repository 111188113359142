import {IApiResponse} from "../../../types/http";
import {postSignUp} from "../../../http/auth";
import {IUserSignup} from "../../../types/auth";

export const signup = async (user: IUserSignup) => {
    const response = await fetch(postSignUp.url, {
        method: postSignUp.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user),
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.token
}
