import {IApiResponse} from "../../../types/http";
import {postSignIn} from "../../../http/auth";
import {IUserSignin} from "../../../types/auth";


export const signin = async (data: IUserSignin) => {
    const response = await fetch(postSignIn.url, {
        method: postSignIn.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.token
}
