import {AIRBORNE_WEIGHT_LEVELS} from "./AIRBORNE_WEIGHT_LEVELS";
import {BONUSES} from "./BONUSES";
import {CONNECTION_LEVELS} from "./CONNECTION_LEVELS";
import {FLEXIBILITY_LEVELS} from "./FLEXIBILITY_LEVELS";
import {NUMBER_OF_MOVEMENTS} from "./NUMBER_OF_MOVEMENTS";
import {ROTATION_LEVELS} from "./ROTATION_LEVELS";
import {THRUST_LEVELS} from "./THRUST_LEVELS";
import {TIME_UNDERWATER_LEVELS} from "./TIME_UNDERWATER_LEVELS";
import {CONNECTION_PLUS_LEVELS} from "./CONNECTION_PLUS_LEVELS";

export const MODIFIERS = {
    'Thrusts': THRUST_LEVELS,
    'Rotations': ROTATION_LEVELS,
    'Flexibility': FLEXIBILITY_LEVELS,
    'Airborne weight': AIRBORNE_WEIGHT_LEVELS,
    'Connections': CONNECTION_LEVELS,
    'Connections +': CONNECTION_PLUS_LEVELS
}


export {
    BONUSES,
    NUMBER_OF_MOVEMENTS,
    TIME_UNDERWATER_LEVELS
}
