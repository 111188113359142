import {createEvent, createStore} from "effector";
import {IDescription, IRoutine, IRoutineFragment, IRoutineFragmentContentTypes} from "../../types";
import {ROUTINE_TYPES} from "../../const/ROUTINE_TYPES";

export const changeDescriptionEvent = createEvent<IDescription>();
export const changeRoutineTypeEvent = createEvent<ROUTINE_TYPES>()
export const changeDurationEvent = createEvent<Date>()
export const addFragmentEvent = createEvent<IRoutineFragment<IRoutineFragmentContentTypes>>()
export const endFragmentEvent = createEvent<number>()
export const removeFragmentEvent = createEvent<IRoutineFragment<IRoutineFragmentContentTypes>>()
export const updateCurrentFragmentEvent = createEvent<IRoutineFragment<IRoutineFragmentContentTypes>>()
export const loadRoutineEvent = createEvent<IRoutine>()

export const $routineStore = createStore<IRoutine>({
    description: {
        team: "",
        athletes: "",
        competition: "",
        theme: "",
    },
    type: ROUTINE_TYPES.FREE_SOLO,
    duration: new Date(172000),
    fragments: [],
})
