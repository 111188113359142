import { useCallback } from "react";
import { useTranslation as useT } from "react-i18next";

type ReturnT = {
    translation: (ts: string, options?: Record<string, unknown>) => string;
};

export const useTranslation = (ns?: string): ReturnT => {
    const { t: tr } = useT(ns);

    const translation = useCallback((ts: string, options = {}) => tr(ts, options), [tr]);
    return { translation };
};
