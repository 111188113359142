import React, {ChangeEvent, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {Translation} from "../../library/common/Translation/Translation";
import {signup} from "../../services/apiRequests/auth";

const Component: React.FC = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [marketingAllowance, setMarketingAllowance] = useState(false);

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleMarketingAllowanceChange = (event: ChangeEvent<HTMLInputElement>) => {
        setMarketingAllowance(event.target.checked)
    }

    const login = () => {
        navigate('../login')
    }

    const handleSignup = async ()=>{
        await signup({email, password, marketingAllowance})
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{
                    margin: 1,
                    backgroundColor: 'secondary.main',
                }}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    <Translation nameSpace='auth' translation='signup'/>
                </Typography>
                <Box sx={{
                    width: '100%', // Fix IE 11 issue.
                    marginTop: 3,
                }}>
                    <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label={<Translation nameSpace='auth' translation='email'/>}
                        name="email"
                        autoComplete="email"
                        onChange={handleEmailChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={<Translation nameSpace='auth' translation='password'/>}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handlePasswordChange}
                    />
                    <FormControlLabel
                        control={<Checkbox value="allowExtraEmails" onChange={handleMarketingAllowanceChange}
                                           color="primary"/>}
                        label={<Translation nameSpace='auth' translation='marketingCheckbox'/>}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSignup}
                    >
                        <Translation nameSpace='auth' translation='signup'/>
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link onClick={login} variant="body2">
                                <Translation nameSpace='auth' translation='signinLinkText'/>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export const SignUp = React.memo(Component);
