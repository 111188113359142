import React from 'react';
import './App.css';
import CssBaseline from "@mui/material/CssBaseline";
import {CalculatorLayout} from "./CalculatorLayout";

const Component = () => {
    return (
        <div>
            <CssBaseline/>
            <CalculatorLayout />
        </div>
    );
}

export const Calculator = React.memo(Component);
