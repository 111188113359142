import {ROUTINE_TYPES} from "../ROUTINE_TYPES";
import {SOLO_TECHNICAL_ELEMENTS} from "./SOLO_TECHNICAL_ELEMENTS";
import {MALE_SOLO_TECHNICAL_ELEMENTS} from "./MALE_SOLO_TECHNICAL_ELEMENTS";
import {DUET_TECHNICAL_ELEMENTS} from "./DUET_TECHNICAL_ELEMENTS";
import {MIXED_DUET_TECHNICAL_ELEMENTS} from "./MIXED_DUET_TECHNICAL_ELEMENTS";
import {TEAM_TECHNICAL_ELEMENTS} from "./TEAM_TECHNICAL_ELEMENTS";


export const TECHNICAL_ELEMENTS = {
    [ROUTINE_TYPES.TECH_SOLO]: SOLO_TECHNICAL_ELEMENTS,
    [ROUTINE_TYPES.TECH_MALE_SOLO]: MALE_SOLO_TECHNICAL_ELEMENTS,
    [ROUTINE_TYPES.TECH_DUET]: DUET_TECHNICAL_ELEMENTS,
    [ROUTINE_TYPES.TECH_MIXED]: MIXED_DUET_TECHNICAL_ELEMENTS,
    [ROUTINE_TYPES.TECH_TEAM]: TEAM_TECHNICAL_ELEMENTS,
}

export type ITechnicalElements =
    keyof typeof SOLO_TECHNICAL_ELEMENTS |
    keyof typeof MALE_SOLO_TECHNICAL_ELEMENTS |
    keyof typeof DUET_TECHNICAL_ELEMENTS |
    keyof typeof MIXED_DUET_TECHNICAL_ELEMENTS |
    keyof typeof TEAM_TECHNICAL_ELEMENTS
