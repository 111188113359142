import {ChangeEvent, FC, memo, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Box} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {Translation} from "../../library/common/Translation/Translation";
import {changePassword} from "../../services/apiRequests/auth";


const Component: FC = () => {
    const navigate = useNavigate()
    const {token} = useParams()
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('')

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleRepeatedPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRepeatedPassword(event.target.value)
    }

    const handlePassportChangeSubmit = async () => {
        if(password !== repeatedPassword) return;
        await changePassword(password, token || '')
        await navigate('/auth/login')
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{
                    margin: 1,
                    backgroundColor: 'secondary.main',
                }}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    <Translation nameSpace='auth' translation='changePassword'/>
                </Typography>
                <Box sx={{
                    width: '100%', // Fix IE 11 issue.
                    marginTop: 1,
                }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={<Translation nameSpace='auth' translation='password'/>}
                        type="password"
                        id="password"
                        onChange={handlePasswordChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="repeated-password"
                        label={<Translation nameSpace='auth' translation='repeatPassword'/>}
                        type="password"
                        id="repeated-password"
                        onChange={handleRepeatedPasswordChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handlePassportChangeSubmit}
                    >
                        <Translation nameSpace='auth' translation='savePassword'/>
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export const SetNewPassword = memo(Component)
