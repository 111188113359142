export enum BONUSES {
    'Travelling' = `Movement of all swimmers of 1.0m or more during the hybrid. If travel occurs only at the
exit of the hybrid (walkout, torpedo/propellor, etc.) this does not count.
If the Hybrid starts at one point in the pool and finishes at another point in the pool,
having covered 1.0m or more, then a traveling bonus will be awarded.
Any pattern changes taking place during the hybrid are not considered as traveling (see
Bonus 5. Pattern Change).
The Traveling bonus may be awarded only once per hybrid`,
    'Placement' = `Moment in routine time when the hybrid is performed. Bonus applied when a hybrid is
performed during the last 20 seconds of the routine. The hybrid must begin within the last
20 seconds of the routine. For example, if an athlete’s solo routine is 2:18 a hybrid can
begin anytime as of 1:58 to earn the placement bonus`,
    'Partial synchronization' = `This bonus is awarded for a fully synchronized part of a hybrid that consists of at least 7
or more movements. The bonus can be added not more than 2 times per hybrid`,
    'Full synchronization' = `This bonus is awarded for a fully synchronized hybrid (a maximum of 3 asynchronous
movements is allowed). The bonus is only awarded for hybrids with 7-19 (level 2), 20-29
(level 3) or 30+ (level 4) movements`,
    'Pattern changes' = `Pattern changes where athletes can see each other and can control the accuracy of
the pattern`,
}
