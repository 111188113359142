import React from 'react';
import {Box} from "@mui/material";
import {FAMILY_TYPES} from "../../../const/FAMILY_TYPES";
import {MODIFIERS} from '../../../const/descriptions';
import {Modifier} from "./Modifier";
import {MODIFIER_CODES} from "../../../const/codes/MODIFIERS";

const Component = () => {
    return (
        <Box sx={{
            flexGrow: 1,
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            minWidth: 0,
            gap: 2,
            padding: 2,
        }}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((level) => {
                return (
                    <React.Fragment>
                        {Object.values(FAMILY_TYPES)
                            .map((family: FAMILY_TYPES) => {
                                const levelString = `LEVEL_${level}`
                                const code = MODIFIER_CODES[family];
                                const fullName = family !== FAMILY_TYPES.CONNECTIONS_PLUS
                                    ? `${code}${level}`
                                    : `${code[0]}${level}${code[1]}`
                                const isExist = Object.keys(MODIFIERS[family]).includes(levelString);
                                // @ts-ignore
                                const description = isExist ? MODIFIERS[family][levelString] : ' ';
                                const data = {code, fullName, description, level, family}
                                return isExist ? <Modifier
                                    data={data}
                                /> : <div>&nbsp;</div>;
                            })}
                    </React.Fragment>
                )
            })}
        </Box>
    )
}

export const SelectModifiers = React.memo(Component);
