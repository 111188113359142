import React, {useState} from 'react';
import ControlPoint from "@mui/icons-material/ControlPoint"
import Forward5 from "@mui/icons-material/Forward5"
import Pause from "@mui/icons-material/Pause"
import PlayArrow from "@mui/icons-material/PlayArrow"
import Replay5 from "@mui/icons-material/Replay5"
import SportsScoreRounded from "@mui/icons-material/SportsScoreRounded"
import StartRounded from "@mui/icons-material/StartRounded"
import VolumeOff from "@mui/icons-material/VolumeOff"
import VolumeUp from "@mui/icons-material/VolumeUp"

import {Box, Button, ButtonGroup, Card, Divider, IconButton, Popover, Slider} from "@mui/material";
import {useStore} from "effector-react";
import {$routineStore} from "../../../stores";
import {ROUTINE_FRAGMENT_TYPES} from "../../../const/ROUTINE_FRAGMENT_TYPES";
import {isTechRoutine} from "../../../services/isTechRoutine";
import {hasAcrobatics} from "../../../services/hasAcrobatics";
import {isDuet} from "../../../services/isDuet";
import {ROUTINE_TYPES} from "../../../const/ROUTINE_TYPES";


type IProps = {
    paused: boolean,
    muted: boolean,
    handlePauseClick: () => void,
    handlePlayClick: () => void,
    handleForwardClick: () => void,
    handleBackwardClick: () => void,
    handleMuteClick: () => void,
    handleUnmuteClick: () => void,
    handleVolumeChange: (volume: number) => void,
    handleRoutineStart: () => void,
    handleNewFragment: (type: ROUTINE_FRAGMENT_TYPES, hybridType?: 'S' | 'D' | 'T') => void,
    handleRoutineFinish: () => void,
}


const Component = (props: IProps) => {
    const routine = useStore($routineStore);

    const [volume, setVolume] = useState(0.3)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.handlePauseClick();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        props.handlePlayClick();
        setAnchorEl(null);
    };

    const isPopoverOpen = Boolean(anchorEl);

    const changeVolume = (_e: Event, value: number | number[]) => {
        const newValue = value as number
        setVolume(newValue);
        props.handleVolumeChange(newValue);
    }

    const createFragment = (type: ROUTINE_FRAGMENT_TYPES, hybridType?: 'S' | 'D' | 'T') => {
        handleClose();
        if (routine.type !== ROUTINE_TYPES.FREE_COMBO) {
            props.handleNewFragment(type);
        } else {
            props.handleNewFragment(type, hybridType)
        }
        props.handlePlayClick();
    }

    return (
        <Card sx={{
            marginTop: 1,
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'center',
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 1,
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 1,
                    }}>
                        <IconButton onClick={props.handleBackwardClick} aria-label="Go back on 5 seconds">
                            <Replay5/>
                        </IconButton>
                        {props.paused ?
                            <IconButton onClick={props.handlePlayClick} aria-label="Play">
                                <PlayArrow sx={{
                                    height: 38,
                                    width: 38,
                                }}/>
                            </IconButton>
                            :
                            <IconButton onClick={props.handlePauseClick} aria-label="Pause">
                                <Pause sx={{
                                    height: 38,
                                    width: 38,
                                }}/>
                            </IconButton>
                        }
                        <IconButton onClick={props.handleForwardClick} aria-label="Bo forward on 5 seconds">
                            <Forward5/>
                        </IconButton>
                    </Box>
                    <Divider orientation='vertical'/>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 1,
                    }}>
                        <IconButton disabled={!!routine.fragments.length} onClick={props.handleRoutineStart}
                                    aria-label="Start routine">
                            <StartRounded/>
                        </IconButton>
                        <IconButton disabled={!routine.fragments.length} onClick={handleClick}
                                    aria-label="Add fragment">
                            <ControlPoint/>
                        </IconButton>
                        <Popover
                            anchorEl={anchorEl}
                            open={isPopoverOpen}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <ButtonGroup>
                                {isTechRoutine(routine.type) &&
                                    <Button onClick={() => createFragment(ROUTINE_FRAGMENT_TYPES.TRE)}>TRE</Button>
                                }
                                {routine.type !== ROUTINE_TYPES.FREE_COMBO &&
                                    <Button
                                        onClick={() => createFragment(ROUTINE_FRAGMENT_TYPES.HYBRID)}>Hybrid</Button>
                                }
                                {routine.type === ROUTINE_TYPES.FREE_COMBO &&
                                    <React.Fragment>
                                        <Button onClick={() => createFragment(ROUTINE_FRAGMENT_TYPES.HYBRID, 'S')}>
                                            Solo Hybrid
                                        </Button>
                                        <Button onClick={() => createFragment(ROUTINE_FRAGMENT_TYPES.HYBRID, 'D')}>
                                            Duet Hybrid
                                        </Button>
                                        <Button onClick={() => createFragment(ROUTINE_FRAGMENT_TYPES.HYBRID, 'T')}>
                                            Team Hybrid
                                        </Button>
                                    </React.Fragment>
                                }
                                <Button
                                    onClick={() => createFragment(ROUTINE_FRAGMENT_TYPES.TRANSITION)}>Transition</Button>
                                {hasAcrobatics(routine.type) &&
                                    <Button
                                        onClick={() => createFragment(ROUTINE_FRAGMENT_TYPES.ACROBATICS)}>Acrobatics</Button>
                                }
                                {isDuet(routine.type) &&
                                    <Button onClick={() => createFragment(ROUTINE_FRAGMENT_TYPES.PAIR_ACROBATICS)}>Pair
                                        Acrobatics</Button>
                                }
                            </ButtonGroup>
                        </Popover>
                        <IconButton disabled={!routine.fragments.length} onClick={props.handleRoutineFinish}
                                    aria-label='Stop routine'>
                            <SportsScoreRounded/>
                        </IconButton>
                    </Box>
                    <Divider orientation='vertical'/>
                    <Box sx={{
                        marginRight: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                        {props.muted ?
                            <IconButton onClick={props.handleUnmuteClick} aria-label="Unmute">
                                <VolumeOff/>
                            </IconButton>
                            :
                            <IconButton onClick={props.handleMuteClick} aria-label="Mute">
                                <VolumeUp/>
                            </IconButton>
                        }
                        <Slider
                            sx={{
                                width: 100,
                            }}
                            onChange={changeVolume}
                            value={volume}
                            min={0}
                            max={1}
                            step={0.1}
                            aria-labelledby="continuous-slider"/>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
}

export const Controls = React.memo(Component)
