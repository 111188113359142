export enum ROUTINE_TYPES {
    TECH_SOLO = 'Technical Solo',
    TECH_MALE_SOLO = 'Technical Male Solo',
    TECH_DUET = 'Technical Duet',
    TECH_MIXED = 'Technical Mixed Duet',
    TECH_TEAM = 'Technical Team',
    FREE_SOLO = 'Free Solo',
    FREE_MALE_SOLO = 'Free Male Solo',
    FREE_DUET = 'Free Duet',
    FREE_MIXED = 'Free Mixed',
    FREE_TEAM = 'Free Team',
    FREE_COMBO = 'Free Combination',
    FREE_HIGHLIGHT = 'Acrobatic Routine',
}
