import React, {ChangeEvent, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Box} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import {signin} from "../../services/apiRequests/auth";
import {Translation} from "../../library/common/Translation/Translation";
import {setJWTToken} from "../../services/setJWTToken";

const Component: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const forgotPassword = ()=>{
        navigate('../restore')
    }

    const signup = ()=>{
        navigate('../signup')
    }

    const login = async ()=>{
        const token = await signin({
            email,
            password,
        })
        setJWTToken(token)
        navigate('/calculator')
    }

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setEmail(event.target.value);
    }

    const onPasswordChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setPassword(event.target.value);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{
                    margin: 1,
                    backgroundColor: 'secondary.main',
                }}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    <Translation nameSpace='auth' translation='singin'/>
                </Typography>
                <Box sx={{
                    width: '100%', // Fix IE 11 issue.
                    marginTop: 3,
                }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={<Translation nameSpace='auth' translation='email'/>}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={onEmailChange}
                        value={email}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={<Translation nameSpace='auth' translation='password'/>}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={onPasswordChange}
                        value={password}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={login}
                    >
                        <Translation nameSpace='auth' translation='singin'/>
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link onClick={forgotPassword} variant="body2">
                                <Translation nameSpace='auth' translation='forgotPasswordLinkText'/>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link onClick={signup} variant="body2">
                                <Translation nameSpace='auth' translation='signupLinkText'/>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export const SignIn = React.memo(Component)
