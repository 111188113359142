import React from 'react';
import {useStore} from "effector-react";
import './App.css';
import {PrimaryComponents} from "./PrimaryComponents";
import {Workplace} from "./Workplace";
import {SecondaryComponents} from "./SecondaryComponents";
import {Box} from "@mui/material";
import {$currentRoutineFragmentStore} from "../../stores";
import {useBeforeunload} from 'react-beforeunload';

const Component = () => {
    const currentRoutineFragment = useStore($currentRoutineFragmentStore)

    useBeforeunload(() => "Changes you made will not be saved");

    return (
        <Box sx={{
            flexGrow: 1,
            padding: 0,
            display: 'grid',
            gridTemplateColumns: '1fr 4fr',
            width: '100vw',
            height: '100vh',
            minWidth: 0,
        }}>
            {currentRoutineFragment ?
                <SecondaryComponents/> :
                <PrimaryComponents/>
            }
            <Workplace/>
        </Box>
    )
}

export const CalculatorLayout = React.memo(Component);
