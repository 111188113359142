export const GROUP_BL = {
    name: 'Group B, Lift',
    code: 'BL',
    description: 'Acrobatic movements in this group are performed on a support/base.',
    construction: [
        {
            name: 'Stack (classic)',
            code: 'St',
            value: 1.05,
        }, {
            name: 'Stack "small"',
            code: 'st',
            value: 0.7,
        }, {
            name: 'Stack head-down',
            code: 'StH',
            value: 0.9,
        }, {
            name: 'Stack head-down "small"',
            code: 'stH',
            value: 0.9,
        }, {
            name: 'Stack head-down + 1 "spotter"',
            code: 'StH’',
            value: 1.15,
        }, {
            name: 'Stack head-down + 2 "spotters"',
            code: 'StH’’',
            value: 1.15,
        }, {
            name: 'Stack head-down in a tuck position',
            code: 'StHt',
            value: 1.15,
        }, {
            name: 'Stack + help (spotter)',
            code: 'St’',
            value: 0.95,
        }, {
            name: 'Stack head-down in pike position + 2 spotters',
            code: 'St’’Hp',
            value: 1.2,
        }, {
            name: 'Stack head-down in  crane position + 2 spotters',
            code: 'St’’Hc',
            value: 1.2,
        }, {
            name: 'Stack + 2 spotters',
            code: 'St’’',
            value: 0.85,
        }, {
            name: 'Stack 2 supports',
            code: '2Sup',
            value: 1.2,
        }, {
            name: 'Stack 2 head-down supports',
            code: '2SupH',
            value: 1.6,
        }, {
            name: 'Stack 2 supports (one of them head-down)',
            code: '2mSup',
            value: 1.4,
        }, {
            name: 'Stack 2 head-down supports + 2 featured-swimmers',
            code: '2SupH(2)',
            value: 1.7,
        }, {
            name: 'Simple Lift',
            code: 'L',
            value: 0.7,
        }, {
            name: 'Lift (classic)',
            code: 'Ꞁ',
            value: 0.6,
        }, {
            name: 'Stack type + 3 «spotters» on surface',
            code: 'StH’’’',
            value: 1.05,
        }, {
            name: 'Stack type + 4 «spotters» on surface',
            code: 'St’’’’',
            value: 1.05,
        }, {
            name: 'Lift on heads',
            code: 'Lh',
            value: 0.7,
        }, {
            name: 'Moving base lift (base swimmers move backward and then return)',
            code: 'LM',
            value: 1.1,
        }, {
            name: 'Moving base lift (base swimmers pass through each other (under featured-swimmer))',
            code: 'LMu',
            value: 1.4,
        }, {
            name: 'Lift two featured-swimmers',
            code: 'L(2)',
            value: 0.7,
        }, {
            name: 'Lift two featured-swimmers on heads',
            code: 'Lh(2)',
            value: 1.1,
        }, {
            name: 'Lift + 2 spotters',
            code: 'L’’',
            value: 0.8,
        }, {
            name: 'Parallel moving base lift',
            code: 'LMp',
            value: 0.8,
        }, {
            name: 'Lift from surface',
            code: 'LSurf',
            value: 0.4,
        }, {
            name: 'Lift + crash',
            code: 'L»',
            value: 0.3,
        }, {
            name: '2 supports Stack + crash in the end',
            code: '2Sup»',
            value: 1.1,
        }, {
            name: 'Lift on 2 heads + spotter',
            code: 'Lh²*',
            value: 0.7,
        }, {
            name: 'Stack + crash',
            code: 'St»',
            value: 0.95,
        }, {
            name: 'Trinity',
            code: 'Trin',
            value: 1.25,
        }, {
            name: 'Stack head-down split + 2 spotters',
            code: 'St’’Hs',
            value: 1.2,
        }
    ],
    direction: [],
    connection: [
        {
            name: 'Palms/palms XS',
            code: 'PPx',
            value: 1.1
        },{
            name: 'Palms/palms',
            code: 'PP',
            value: 1.0
        },{
            name: 'Feet (featured-swimmer) on palms (support) XS',
            code: 'FPx',
            value: 1.05
        },{
            name: 'Feet (featured-swimmer) on palms (support)',
            code: 'FP',
            value: 0.95
        },{
            name: 'Feet (featured-swimmer) on feet (support)',
            code: 'FF',
            value: 0.7
        },{
            name: 'Palms (featured-swimmer) on feet (support)',
            code: 'PF',
            value: 0.45
        },{
            name: 'Lower back (touch/not sit) on shoulder blades (blind connection)',
            code: 'SiSb',
            value: 0.5
        },{
            name: 'Backpack” grip Shoulder blades (featured swimmer) / Shoulder blades (support)',
            code: 'Bp',
            value: 0.3
        },{
            name: 'Shoulders (featured-swimmer) on feet',
            code: 'ShF',
            value: 0.3
        },{
            name: '"Eiffel" grip: Palms on shoulder / palms on shoulders',
            code: 'E',
            value: 0.45
        },{
            name: '"Icarus" - Feet (f.swimmer)/feet bent (support) or feet/feet+2 «spotter»s on the side holding featured swimmer’s hands',
            code: 'I',
            value: 0.5
        },{
            name: 'Palm (featured-swimmer) on head (support) + palm / palm',
            code: 'PH/',
            value: 0.8
        },{
            name: 'Lift on 4 heads of base-featured-swimmers',
            code: 'Li4H',
            value: 0.3
        },{
            name: '"Window" grip: All f.swimmer’s body (connection by shoulders) on a shoulder + extra help',
            code: 'W',
            value: 0.4
        },{
            name: '"Pyramid" grip: Head on head + palm / palm + leg hold by featured-swimmers palm',
            code: 'Py',
            value: 0.4
        },{
            name: 'All featured-swimmer’s body on palms (lay or sit)',
            code: 'AP',
            value: 0.45
        },{
            name: 'Shoulders (featured-swimmer) on feet + "spotters"',
            code: 'ShF*',
            value: 0.1
        },{
            name: 'Sultan: Back/back + featured-swimmer holds support, and support holds featured-swimmer',
            code: 'Su',
            value: 0.1
        },{
            name: '"Table" grip: Construction 2 support athletes head-down, featured-swimmer lay on their feet',
            code: 'Ta',
            value: 0.15
        },{
            name: 'Sit or Lay on shoulders',
            code: 'SiS',
            value: 0.1
        },{
            name: 'Feet (featured-swimmer) on shoulders (support) while stack is lifted up and switch on 1 foot for main phase',
            code: 'F1S',
            value: 0.1
        },{
            name: 'Feet (featured-swimmer) on shoulders (support)',
            code: 'FS',
            value: 0.05
        },{
            name: 'Foot on a shoulder + connection with support athlete',
            code: 'F1S/',
            value: 0.2
        },{
            name: '"Lemur" grip: Construction 2 support athletes head-up, featured swimmer lay on their hands or in a head-down position (or featured swimmer\n' +
                'hold the shoulders of one of the supports)',
            code: 'Le',
            value: 0.1
        },{
            name: 'Simple lift (base athletes hold featured-swimmer) Or "Full body" Lift on hands',
            code: 'Li',
            value: 0.1
        },{
            name: '"Chameleon" grip: Construction 2 supports, one of them head-down; featured swimmer connects to them by stomach, hands and legs (3points)',
            code: 'Ch',
            value: 0.2
        },{
            name: 'Twins (Featured swimmer holds the stomach of support and support holds the pelvis of featured-swimmer)',
            code: 'Tw',
            value: 0.1
        },{
            name: 'Twins + spotters (Featured swimmer holds the stomach of support and support holds the pelvis of featured-swimmer)',
            code: 'Tw*',
            value: 0.1
        },{
            name: 'All featured-swimmer’s body (Lays) on feet (legs of support spread)',
            code: 'AV',
            value: 0.1
        },{
            name: 'All body on feet + 4 spotters',
            code: 'AF*',
            value: 0.1
        },{
            name: 'Split on split',
            code: 'SpSp',
            value: 0.1
        },{
            name: 'Sit on feet (Buttocks or Stomach)',
            code: 'Sif',
            value: 0.15
        },{
            name: 'Back/Back + blind capture',
            code: 'BBb',
            value: 0.25,
        },{
            name: 'Lift + spotter pair',
            code: 'Li*',
            value: 0.1
        },{
            name: 'Cowboy sit on (spread legs) feet',
            code: 'SiV',
            value: 0.2
        },{
            name: 'All featured-swimmer’s body on palms (sit)+ featured-swimmer has additional support on head',
            code: 'AP/',
            value: 0.35
        },{
            name: 'Palms on 2 heads + spotter',
            code: 'PH*',
            value: 0.3
        },{
            name: 'Construction 2 support athletes head-up, f.swimmer stay 1 leg on a head of first support\n' +
                'and 2nd leg on palms (near head)',
            code: 'FHP/',
            value: 0.55
        },{
            name: 'Feet on Feet + additional help on the sides',
            code: 'FF*',
            value: 0.5
        },{
            name: 'All body on leg + connect with leg',
            code: 'AL/',
            value: 0.1
        },{
            name: 'Foot on palms + additional support',
            code: 'FP*',
            value: 0.4
        },{
            name: 'Sit or lay on fit + spotters',
            code: 'SiF*',
            value: 0.1
        },{
            name: 'All body on palms + extra catch the support',
            code: 'AP\\',
            value: 0.1
        },
    ],
    position: [
        {
            name: 'Lady',
            code: 'ld',
            value: 0.1
        }, {
            name: 'Lady (pos 2)',
            code: '2ld',
            value: 0.05
        }, {
            name: 'Heron',
            code: 'he',
            value: 0.15
        }, {
            name: 'Heron (pos 2)',
            code: '2he',
            value: 0.05
        }, {
            name: 'Crane',
            code: 'cr',
            value: 0.2
        }, {
            name: 'Crane (pos 2)',
            code: '2cr',
            value: 0.1
        }, {
            name: 'Kitri',
            code: 'kr',
            value: 0.25
        }, {
            name: 'Kitri (pos 2)',
            code: '2kr',
            value: 0.2
        }, {
            name: 'Vertical split',
            code: 'vs',
            value: 0.45
        }, {
            name: 'Vertical split (pos 2)',
            code: '2vs',
            value: 0.3
        }, {
            name: 'Swan',
            code: 'sw',
            value: 0.4
        }, {
            name: 'Swan (pos 2)',
            code: '2sw',
            value: 0.3
        }, {
            name: 'Glass',
            code: 'gl',
            value: 0.5
        }, {
            name: 'Glass (pos 2)',
            code: '2gl',
            value: 0.4
        }, {
            name: 'Ballerina',
            code: 'ba',
            value: 0.25
        }, {
            name: 'Ballerina (pos 2)',
            code: '2ba',
            value: 0.1
        }, {
            name: 'Eagle',
            code: 'ea',
            value: 0.35
        }, {
            name: 'Eagle (pos 2)',
            code: '2ea',
            value: 0.25
        }, {
            name: 'Sail',
            code: 'sa',
            value: 0.45
        }, {
            name: 'Sail (pos 2)',
            code: '2sa',
            value: 0.3
        }, {
            name: 'Needle',
            code: 'ne',
            value: 0.55
        }, {
            name: 'Needle (pos 2)',
            code: '2ne',
            value: 0.45
        }, {
            name: 'Eye',
            code: 'ey',
            value: 0.65
        }, {
            name: 'Eye (pos 2)',
            code: '2ey',
            value: 0.4
        }, {
            name: 'Line',
            code: 'ln',
            value: 0.1
        }, {
            name: 'Line (pos 2)',
            code: '2ln',
            value: 0.1
        }, {
            name: 'Dove',
            code: 'do',
            value: 0.15
        }, {
            name: 'Dove (pos 2)',
            code: '2do',
            value: 0.1
        }, {
            name: 'Sit',
            code: 'si',
            value: 0.05
        }, {
            name: 'Sit (pos 2)',
            code: '2si',
            value: 0.05
        }, {
            name: 'Monkey',
            code: 'mo',
            value: 0.1
        }, {
            name: 'Monkey (pos 2)',
            code: '2mo',
            value: 0.1
        }, {
            name: 'Shrimp',
            code: 'sh',
            value: 0.2
        }, {
            name: 'Shrimp (pos 2)',
            code: '2sh',
            value: 0.2
        }, {
            name: 'Split',
            code: 'spl',
            value: 0.2
        }, {
            name: 'Split (pos 2)',
            code: '2spl',
            value: 0.2
        }, {
            name: 'Peacock',
            code: 'pe',
            value: 0.2
        }, {
            name: 'Peacock (pos 2)',
            code: '2pe',
            value: 0.1
        }, {
            name: 'Crocodile',
            code: 'cd',
            value: 0.3
        }, {
            name: 'Crocodile (pos 2)',
            code: '2cd',
            value: 0.2
        }, {
            name: 'Scissors',
            code: 'sc',
            value: 0.15
        }, {
            name: 'Scissors (pos 2)',
            code: '2sc',
            value: 0.05
        }, {
            name: 'Pirate',
            code: 'pt',
            value: 0.15
        }, {
            name: 'Pirate (pos 2)',
            code: '2pt',
            value: 0.05
        }, {
            name: 'Cobra',
            code: 'co',
            value: 0.2
        }, {
            name: 'Cobra (pos 2)',
            code: '2co',
            value: 0.05
        }, {
            name: 'Mermaid',
            code: 'mr',
            value: 0.15
        }, {
            name: 'Mermaid (pos 2)',
            code: '2mr',
            value: 0.05
        }, {
            name: 'Sunbathe',
            code: 'sb',
            value: 0.25
        }, {
            name: 'Sunbathe (pos 2)',
            code: '2sb',
            value: 0.1
        }, {
            name: 'Birch',
            code: 'bi',
            value: 0.25
        }, {
            name: 'Birch (pos 2)',
            code: '2bi',
            value: 0.1
        }, {
            name: 'Flamingo',
            code: 'fl',
            value: 0.25
        }, {
            name: 'Flamingo (pos 2)',
            code: '2fl',
            value: 0.1
        }, {
            name: 'Scorpio',
            code: 'so',
            value: 0.3
        }, {
            name: 'Scorpio (pos 2)',
            code: '2so',
            value: 0.05
        }, {
            name: 'Turtle',
            code: 'tu',
            value: 0.3
        }, {
            name: 'Turtle (pos 2)',
            code: '2tu',
            value: 0.1
        }, {
            name: 'Seastar',
            code: 'se',
            value: 0.35
        }, {
            name: 'Seastar (pos 2)',
            code: '2se',
            value: 0.1
        }, {
            name: 'Pin',
            code: 'pi',
            value: 0.6
        }, {
            name: 'Pin (pos 2)',
            code: '2pi',
            value: 0.45
        }, {
            name: 'Rose',
            code: 'ro',
            value: 0.2
        }, {
            name: 'Rose (pos 2)',
            code: '2ro',
            value: 0.05
        }, {
            name: 'Lamp post',
            code: 'lp',
            value: 0.25
        }, {
            name: 'Lamp post (pos 2)',
            code: '2lp',
            value: 0.15
        }, {
            name: 'Box',
            code: 'bo',
            value: 0.3
        }, {
            name: 'Box (pos 2)',
            code: '2bo',
            value: 0.1
        }, {
            name: 'Bamboo',
            code: 'bb',
            value: 0.3
        }, {
            name: 'Bamboo (pos 2)',
            code: '2bb',
            value: 0.1
        }, {
            name: 'Iguana',
            code: 'ig',
            value: 0.35
        }, {
            name: 'Iguana (pos 2)',
            code: '2ig',
            value: 0.2
        }, {
            name: 'Knight',
            code: 'kn',
            value: 0.35
        }, {
            name: 'Knight (pos 2)',
            code: '2kn',
            value: 0.15
        }, {
            name: 'Willow',
            code: 'wi',
            value: 0.4
        }, {
            name: 'Willow (pos 2)',
            code: '2wi',
            value: 0.15
        }, {
            name: 'Beluga',
            code: 'be',
            value: 0.4
        }, {
            name: 'Beluga (pos 2)',
            code: '2be',
            value: 0.2
        }, {
            name: 'Tower',
            code: 'to',
            value: 0.45
        }, {
            name: 'Tower (pos 2)',
            code: '2to',
            value: 0.15
        }, {
            name: 'Owl',
            code: 'ow',
            value: 0.45
        }, {
            name: 'Owl (pos 2)',
            code: '2ow',
            value: 0.2
        }, {
            name: 'Bridge',
            code: 'br',
            value: 0.45
        }, {
            name: 'Bridge (pos 2)',
            code: '2br',
            value: 0.2
        }, {
            name: 'Drop',
            code: 'dr',
            value: 0.6
        }, {
            name: 'Drop (pos 2)',
            code: '2dr',
            value: 0.3
        }, {
            name: 'Queen',
            code: 'qu',
            value: 1.0
        }, {
            name: 'Queen (pos 2)',
            code: '2qu',
            value: 0.5
        }
    ],
    rotationOfBase: [
        {
            name: 'Value for Stack (only support swimmer with featured-swimmer on top rotates around self) 180°',
            code: 'r0,5',
            value: 0.2
        }, {
            name: 'Value for Stack (only support swimmer with featured-swimmer on top rotates around self) 360°',
            code: 'r1',
            value: 0.3
        }, {
            name: 'Value for Stack (only support swimmer with featured-swimmer on top rotates around self) 540°',
            code: 'r1,5',
            value: 0.4
        }, {
            name: 'Value for Stack (only support swimmer with featured-swimmer on top rotates around self) 720°',
            code: 'r2',
            value: 0.5
        }, {
            name: 'Value for Stack (featured swimmer stands on 1 leg and other one is 135 or 180 degrees) 180°',
            code: 'R0,5',
            value: 0.25
        }, {
            name: 'Value for Stack (featured swimmer stands on 1 leg and other one is 135 or 180 degrees) 360°',
            code: 'R1',
            value: 0.35
        }, {
            name: 'Value for Stack (featured swimmer stands on 1 leg and other one is 135 or 180 degrees) 540°',
            code: 'R1,5',
            value: 0.45
        },  {
            name: 'Value for Stack (featured swimmer stands by both feet on supports shoulders) 180°',
            code: 'r0,5*',
            value: 0.05,
        }, {
            name: 'Value for Stack (featured swimmer stands by both feet on supports shoulders) 360°',
            code: 'r1*',
            value: 0.1,
        }, {
            name: 'Value for Stack (featured swimmer stands by both feet on supports shoulders) 540°',
            code: 'r1,5*',
            value: 0.15,
        }, {
            name: 'Value for Stack (featured swimmer stands by both feet on supports shoulders) 720°',
            code: 'r2*',
            value: 0.2,
        }, {
            name: 'Value for Stack (if featured-swimmer is in a handstand position; or support position is head-down; or both are head-down (shoulders on feet connect) 180°',
            code: 'r0,5!',
            value: 0.3,
        }, {
            name: 'Value for Stack (if featured-swimmer is in a handstand position; or support position is head-down; or both are head-down (shoulders on feet connect) 360°',
            code: 'r1!',
            value: 0.5,
        }, {
            name: 'Value for Stack (if featured-swimmer is in a handstand position; or support position is head-down; or both are head-down (shoulders on feet connect) 540°',
            code: 'r1,5!',
            value: 0.7,
        }, {
            name: 'Value for Lift (big water resistance for base athletes while all construction rotates including base swimmers) 90°',
            code: 'r/L',
            value: 0.3,
        }, {
            name: 'Value for Lift (big water resistance for base athletes while all construction rotates including base swimmers) 180°',
            code: 'r0,5L',
            value: 0.4,
        }, {
            name: 'Value for Lift (big water resistance for base athletes while all construction rotates including base swimmers) 360°',
            code: 'r1L',
            value: 0.5,
        },
    ],
    bonus: [
        {
            name: 'Synchronized actions for double acrobatic movements',
            code: 'w1',
            value: 0.2,
        }, {
            name: 'Rotation 180° or 360° on feet without leaving support',
            code: 'w2',
            value: 0.3,
        }, {
            name: 'In 2Support construction, twirl one of the supports',
            code: 'w3',
            value: 0.1,
        }, {
            name: 'Stand-up (lifting torso) from head-down position',
            code: 'w4',
            value: 0.2,
        }, {
            name: 'Connection between 2 featured-swimmers',
            code: 'w5',
            value: 0.1,
        }, {
            name: ' Blind grip for Lifts',
            code: 'w6',
            value: 0.2,
        }, {
            name: 'Third position (example: in the end of acrobatic movement closing legs to vertical)',
            code: 'w7',
            value: 0.05,
        }, {
            name: 'Long holding lift (3 seconds and more) - doesn’t apply for rotation of the construction or "moving base lifts"',
            code: 'w8',
            value: 0.2,
        }, {
            name: '"Twirl" of featured-swimmer in group B',
            code: 'w9',
            value: 0.05,
        }, {
            name: '"Wave" movements',
            code: 'w10',
            value: 0.1,
        }, {
            name: 'Featured-swimmer rotates on feet or palms of support 180°',
            code: 'w11',
            value: 0.1,
        }, {
            name: 'Featured-swimmer rotates on feet or palms of support 180°',
            code: 'w12',
            value: 0.2,
        }, {
            name: 'Travelling construction (at least 1 meter)',
            code: 'w13',
            value: 0.1,
        }, {
            name: '"Moonwalk": Lift-up from split - legs sliding and changing place and opening back to the split on surface',
            code: 'w14',
            value: 0.2,
        }, {
            name: '"Ungrip"',
            code: 'w15',
            value: 0.05,
        },
    ],
    rotation: [],
}
