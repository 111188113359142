 export enum FLEXIBILITY_LEVELS {
    LEVEL_1 = `Rapid split by one leg from any position (such as Pike, Tub, Tuck, Inverted Tuck, Bent
Knee VP, Fishtail, VP, etc.)`,
    LEVEL_2 = `Clearly demonstrated split (held at least 1-2 seconds)\n
Walkout Front\n
Back Layout to Surface Arch or Bent Knee Surface Arch `,
    LEVEL_3 = `Ariana rotation or split variants at the surface with a demonstration of at least 2 different
splits (Right, Left, Middle)\n
Split to Split through Vertical Position (changing legs)`,
    LEVEL_4 = `Front Layout to Surface Bent Knee Arch Position or a Split (Example – Swordfish like
movement)\n
From Surface Arch Position to Knight or Split\n
Bent Knee Surface Arch to Bent Knee VP`,
    LEVEL_5 = `Knights: combinations of Knight positions (at least 2)\n
Knight to Fishtail (though Vertical Position)\n
Knight to Vertical Position\n
Sustained Knight Position (held at least 1-2 seconds)\n
Bent Knee Surface Arch to Vertical Position`,
    LEVEL_6 = `Surface Arch to Vertical Position`
}
