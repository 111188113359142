import {TRE_VARIANTS} from "./TRE_VARIANTS";
import {ROUTINE_TYPES} from "../ROUTINE_TYPES";

export const DUET_TECHNICAL_ELEMENTS = {
    TRE1a: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 1,
        TREVariant: TRE_VARIANTS.A,
        description: 'Walkover Back Closing 360° – Continuous Spin 1080°',
    },
    TRE1b: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 1,
        TREVariant: TRE_VARIANTS.B,
        description: 'Walkover Back Closing 180° – Continuous Spin 720°',
    },
    TRE2a: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 2,
        TREVariant: TRE_VARIANTS.A,
        description: 'Rocket Split Alternating Legs – Spinning 180°',
    },
    TRE2b: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 2,
        TREVariant: TRE_VARIANTS.B,
        description: 'Rocket Split – Spinning 180°',
    },
    TRE3a: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 3,
        TREVariant: TRE_VARIANTS.A,
        description: 'Flamingo Full Twist Hybrid',
    },
    TRE3b: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 3,
        TREVariant: TRE_VARIANTS.B,
        description: 'Flamingo Half Twist Hybrid',
    },
    TRE4a: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 4,
        TREVariant: TRE_VARIANTS.A,
        description: 'Fishtail – Knight - Continuous Spin 1080°',
    },
    TRE4b: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 4,
        TREVariant: TRE_VARIANTS.B,
        description: 'Fishtail – Knight - Continuous Spin 720°',
    },
    TRE5a: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 5,
        TREVariant: TRE_VARIANTS.A,
        description: 'Thrust Bent Knee Twirl Spin 360°',
    },
    TRE5b: {
        routineType: ROUTINE_TYPES.TECH_DUET,
        TRENumber: 5,
        TREVariant: TRE_VARIANTS.B,
        description: 'Thrust - Bent Knee Twirl',
    },
}
