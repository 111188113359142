export enum ROTATION_LEVELS {
    LEVEL_1 = `One or two legs:\n
Swirl 180º-360º\n
Turning 180º-360º while doing other non-sustained or “up-down” actions`,
    LEVEL_2 = `One or two legs:\n
Swirl 720º-1080º\n
Spin descending 180º\n
Twist or Twirl 180º with 1 leg only`,
    LEVEL_3 = `One or two legs:\n
Swirl 1440º\n
Spin ascending 180º-360º\n
Spin descending 360º-720º\n
Twist or Twirl 180° with 2 legs\n
Twist 360° with 1 leg only`,
    LEVEL_4 = `One or two legs:\n
Spin ascending 720º-1080º\n
Spin descending 1080º-1440º\n
Twist 360º with 2 legs`,
    LEVEL_5 = `One or two legs:\n
Twist 720º with 1 leg\n
Spin ascending 1440º with 1 leg\n   
Spin descending more than 1440º with 2 legs\n
Twist opening 360º Vertical Position to Split\n
Twirl 360º with 2 legs\n
Combined Spin 360º-720º with 1 leg\n
Reverse Combined Spin 360º-720º with 1 leg`,
    LEVEL_6 = `One or two legs:\n
Combined Spin 360º with 2 legs\n
Reverse Combined Spin 360º with 2 legs\n
Two-Direction Combined or Reverse Combined Spin 360º-720° with 1 leg\n
Spin ascending 1440° with 2 legs\n
Twist 720º with 2 legs\n
Twist Closing 360º from Split to Vertical Position`,
    LEVEL_7 = `Two legs only:\n
Combined Spin 720°\n
Reverse Combined Spin 720°\n
Two-Direction Combined or Reverse Combined Spin 360º\n
Twist 1080º\n
Unbalanced 360° Twist\n
Two-Direction Twist 360°`,
    LEVEL_8 = `Two legs only:\n
Combined Spin 1080°\n
Reverse Combined Spin 1080°\n
Two-Direction Combined or Reverse Combined Spin 720º\n
Twist 1440º\n
Unbalanced 720° Twist\n
Two-Direction Twist 720°`,
    LEVEL_9 = `Two legs only:\n
Combined Spin 1440º\n
Reverse Combined Spin 1440º\n
Two-Direction Combined or Reverse Combined Spin 1080°\n
Unbalanced 1080° Twist`
}
