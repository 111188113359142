import {GROUP_AW} from "./GROUP_AW";
import {GROUP_BL} from "./GROUP_BL";

export const GROUP_CC = {
    name: 'Group C, Onto Support',
    code: 'CC',
    description: 'Encompasses characteristics of both Airborne and Balance groups',
    construction: [
        {
            name: 'On to support: Stack from simple throw. Transit,not jump!',
            code: 'Thr~St',
            value: 1.7,
        }, {
            name: 'Jump through support from "spotter" (Stack type+"spotter"). Transit, not jump',
            code: '‘~St>',
            value: 1.65,
        }, {
            name: 'Jump through support’s shoulders from "spotter" (Stack type+"spotter")',
            code: '‘>StSh>`',
            value: 1.55,
        }, {
            name: 'Jump on support’s shoulders from spotter and remain until submergence',
            code: '‘>StSh',
            value: 1.55,
        }, {
            name: 'Onto support from "spotter" (Stack type+"spotter"). Transit, not jump',
            code: '‘~St',
            value: 1.75,
        }, {
            name: 'Onto support from "spotter" (Stack type+"spotter") 3 points grip. Transit, not jump',
            code: '‘~St*',
            value: 1.55,
        }, {
            name: 'Jump through head-down support',
            code: '‘>StH>',
            value: 1.75,
        }, {
            name: 'Through: 2 pair + featured-swimmer (one base swimmer heads-down)',
            code: '‘>’H>',
            value: 1.4,
        }, {
            name: 'Through: 2 pair + featured-swimmer (all base swimmers head-up)',
            code: '‘>’>',
            value: 1.1,
        }, {
            name: 'Through: Platform from 2+"spotter"/thrower',
            code: 'Thr>PP>',
            value: 1.35,
        }, {
            name: 'Through: Platform + "spotter" /thrower',
            code: '‘>P>',
            value: 1.25,
        }, {
            name: 'Through: run on 3 backs (Platform from 3 swimmers + "spotters"/throwers',
            code: 'Thr>Pb₃>',
            value: 1.6,
        }, {
            name: 'Through: Roll on a float-platform and jump from it',
            code: 'Roll>P>',
            value: 0.7,
        }, {
            name: 'Onto: jump from dynamic stack on "balance" stack and remain on palms',
            code: 'St>Stp',
            value: 1.85,
        }, {
            name: 'Onto: jump from spotter pair on "balance" stack and remain on shoulders',
            code: '‘>Stsh',
            value: 1.65,
        }, {
            name: 'On support: Jump on Stack head-down from simple throw',
            code: 'Thr>StH',
            value: 2.0,
        }, {
            name: 'C: lift + "spotter" (fly above formation)',
            code: '‘↷L',
            value: 1.45,
        }, {
            name: 'Through: simple throw, featured-swimmer fly above lift',
            code: 'Thr↷L',
            value: 1.3,
        }, {
            name: 'Through: Stack-type + head-down "spotter" pair',
            code: 'St>’H>',
            value: 1.75,
        }, {
            name: 'Through: Two stack-type + mini-stack',
            code: '‘>StSt>',
            value: 1.8,
        }, {
            name: 'Onto support: throw on a platform',
            code: 'Thr>P',
            value: 1.45,
        }, {
            name: 'Other: simple lift + "spotter"',
            code: 'L’',
            value: 1.15,
        }, {
            name: 'Through formation from hands + "spotter"',
            code: 'Thr>hand>',
            value: 0.7,
        }, {
            name: 'Through base swimmers from simple throw',
            code: 'Thr>base>',
            value: 1.05,
        }, {
            name: 'Other: Stack+throw (2 f.swimmers in connection with each-other)',
            code: 'St+Thr(2)',
            value: 1.85,
        }, {
            name: 'Other: snack-stack type',
            code: 'Sn',
            value: 0.95,
        }, {
            name: 'Other: snack-stack type head-down',
            code: 'SnH',
            value: 1.35,
        }, {
            name: 'Through: Jump from stack with connection with 2 stack and broke it later',
            code: 'St>St>',
            value: 1.6,
        }, {
            name: 'Other: Stack + 2 spotters',
            code: 'St’’',
            value: 1.45,
        }, {
            name: 'Onto: "Monkey" jump from spotter pair on "balance" stack and remain on shoulders',
            code: '‘<Stm',
            value: 1.55,
        }, {
            name: 'Through: Stack from simple throw. Transit,not jump',
            code: 'Thr~St>',
            value: 1.5,
        }, {
            name: 'Through: featured-swimmer passes through surface hand-grip of base swimmers',
            code: '>HandSurf>',
            value: 0.5,
        }, {
            name: 'Through lift from "spotter"',
            code: '‘>L>',
            value: 1.45,
        }, {
            name: '"Toss" (from surface through hands)',
            code: 'Toss>hand>',
            value: 0.5,
        }, {
            name: 'Through 3 heads from mini-stack',
            code: 'Thr>3head>',
            value: 1.2,
        }, {
            name: 'Through formation from hands + "spotters" + 2 featured-swimmers',
            code: '(2)Thr>hand>',
            value: 0.8,
        }, {
            name: 'Through: run on 2 backs (Platform from 2 swimmers + "spotters"/throwers',
            code: 'Thr>Pb²>',
            value: 1.6,
        }, {
            name: 'Onto: Jump from spotter on Stack, palms',
            code: '‘>Stp',
            value: 1.75,
        }, {
            name: 'Fall from one formation on the "hand-formation"',
            code: 'L>hand',
            value: 1.0,
        }
    ],
    direction: [
        {
            name: 'Forwards (no somersault, no twist)',
            code: 'Forw',
            value: 0.05,
        },{
            name: 'Backwards',
            code: 'Back',
            value: 0.1,
        },{
            name: 'Forwards (with somersault/twist)',
            code: 'FORW',
            value: 0.15,
        },{
            name: 'Sideways',
            code: 'Side',
            value: 0.2,
        },{
            name: 'Upwards',
            code: 'Up',
            value: 0.05,
        },{
            name: 'Reverse',
            code: 'Rev',
            value: 0.2,
        },
    ],
    connection: [],
    position: [
        ...GROUP_AW.position.map((item)=>{
            const newItem = {...item};
            newItem.name = `A: ${item.name}`;
            return newItem;
        }),
        ...GROUP_BL.position.map((item)=>{
            const newItem = {...item};
            newItem.name = `B: ${item.name}`;
            return newItem;
        }),
        {
            name: 'Passing Tuck/Pike/Mantis/Monkey',
            code: 'ps',
            value: 0.05,
        },{
            name: 'Passing Line',
            code: 'psl',
            value: 0.1,
        },
    ],
    rotationOfBase: [
        {
            name: 'Value for Stack (only support swimmer with featured-swimmer on top rotates around self) 180°',
            code: 'r0,5',
            value: 0.2
        }, {
            name: 'Value for Stack (only support swimmer with featured-swimmer on top rotates around self) 360°',
            code: 'r1',
            value: 0.3
        }, {
            name: 'Value for Stack (only support swimmer with featured-swimmer on top rotates around self) 540°',
            code: 'r1,5',
            value: 0.4
        }, {
            name: 'Value for Stack (if featured-swimmer is in a handstand position; or support position is head-down; or both are head-down (shoulders on feet connect) 180°',
            code: 'r0,5!',
            value: 0.3,
        }, {
            name: 'Value for Stack (if featured-swimmer is in a handstand position; or support position is head-down; or both are head-down (shoulders on feet connect) 360°',
            code: 'r1!',
            value: 0.5,
        }, {
            name: 'Value for Stack (if featured-swimmer is in a handstand position; or support position is head-down; or both are head-down (shoulders on feet connect) 540°',
            code: 'r1,5!',
            value: 0.7,
        },
    ],
    rotation: [
        {
            name: 'Horizontal plane (twist) for "head-up" position 180°',
            code: 'T0,5',
            value: 0.1
        },{
            name: 'Horizontal plane (twist) for "head-up" position 360°',
            code: 'T1',
            value: 0.15
        },{
            name: 'Horizontal plane (twist) for "head-up" position 540°',
            code: 'T1,5',
            value: 0.2
        },{
            name: 'Horizontal plane (twist) for "head-up" position 720°',
            code: 'T2',
            value: 0.25
        },{
            name: 'Horizontal plane (twist) when twist executed in the same time with\n' +
                'somersault and other "not head-up" twists  180°',
            code: 't0,5',
            value: 0.1
        },{
            name: 'Horizontal plane (twist) when twist executed in the same time with\n' +
                'somersault and other "not head-up" twists  360°',
            code: 't1',
            value: 0.2
        },{
            name: 'Horizontal plane (twist) when twist executed in the same time with\n' +
                'somersault and other "not head-up" twists  540°',
            code: 't1,5',
            value: 0.3
        },{
            name: 'Horizontal plane (twist) when twist executed in the same time with\n' +
                'somersault and other "not head-up" twists  720°',
            code: 't2',
            value: 0.4
        },{
            name: 'Saggital plane 180°',
            code: 's0,5',
            value: 0.05,
        },{
            name: 'Saggital plane 360°',
            code: 's1',
            value: 0.3,
        },{
            name: 'Saggital plane 540°',
            code: 's1,5',
            value: 0.5,
        },{
            name: 'Saggital plane 720°',
            code: 's2',
            value: 0.6,
        },{
            name: 'Frontal plane 360°',
            code: 'f1',
            value: 0.4,
        },{
            name: 'Frontal plane 540°',
            code: 'f1,5',
            value: 0.6,
        },{
            name: 'Frontal plane 720°',
            code: 'f2',
            value: 0.7,
        },{
            name: 'Dive (not 180° somersault)',
            code: 'd',
            value: 0.025,
        },{
            name: 'Dive + 180° twist',
            code: 'dt0,5',
            value: 0.125,
        },{
            name: 'Dive + 360° twist',
            code: 'dt1,0',
            value: 0.175,
        },{
            name: 'Dive + 540° twist',
            code: 'dt1,5',
            value: 0.225,
        },{
            name: 'Two axis airborne rotation half somersault + half twist (small jumps only)',
            code: 'S0,5t0,5',
            value: 0.3,
        },{
            name: 'Two axis airborne rotation 1 somersault + 0,5 twist',
            code: 's1t0,5',
            value: 0.4,
        },{
            name: 'Two axis airborne rotation 1 somersault + 1 twist',
            code: 's1t1',
            value: 0.5,
        },{
            name: 'Two axis airborne rotation 1 somersault + 1,5 twist',
            code: 's1t1,5',
            value: 0.6,
        },{
            name: 'Two axis airborne rotation 1 somersault + 2 twist',
            code: 's1t2',
            value: 0.7,
        },{
            name: 'Two axis airborne rotation 1 somersault + 2,5 twist',
            code: 's1t2,5',
            value: 0.8,
        },{
            name: 'Two axis airborne rotation 1,5 somersault + 0,5 twist',
            code: 's1.5t0,5',
            value: 0.6,
        },{
            name: 'Two axis airborne rotation 1,5 somersault + 1 twist',
            code: 's1,5t1',
            value: 0.7,
        },{
            name: 'Two axis airborne rotation 2 somersault + 0,5 twist',
            code: 's2t0,5',
            value: 0.9,
        },{
            name: 'Two axis airborne rotation 2 somersault + 1 twist',
            code: 's2t1',
            value: 1,
        },{
            name: 'Handspring',
            code: 'h',
            value: 0.1,
        },{
            name: 'Cartwheel',
            code: 'c',
            value: 0.1,
        }
    ],
    bonus: [
        {
            name: 'Jump on the Stack and remain on it until submergence',
            code: 'y1',
            value: 0.3,
        }, {
            name: 'Running on the (3) backs',
            code: 'y2',
            value: 0.3,
        }, {
            name: 'Running on the (2) backs',
            code: 'y3',
            value: 0.2,
        }, {
            name: 'Running on the (1) back (should lay not sideways to featured-swimmer)',
            code: 'y4',
            value: 0.1,
        }, {
            name: 'Fly above formation',
            code: 'y5',
            value: 0.3,
        }, {
            name: 'Blind grip in group C ',
            code: 'y6',
            value: 0.2,
        }, {
            name: 'Synchronized actions for double acrobatic movements',
            code: 'y7',
            value: 0.2,
        }, {
            name: '"Rolling" on a construction',
            code: 'y8',
            value: 0.1,
        }, {
            name: 'Connection between 2 featured-swimmers;',
            code: 'y9',
            value: 0.1,
        }, {
            name: 'Third position (example: in the end of acrobatic movement tucking (group A)',
            code: 'y10',
            value: 0.05,
        }, {
            name: 'Featured-swimmer "Slips through" after jump between support’s legs',
            code: 'y11',
            value: 0.1,
        }, {
            name: 'Blind jump',
            code: 'y12',
            value: 0.05,
        }, {
            name: '"Hulahoop" action (Featured-swimmer in ring position enters water with support swimmer inside the circle (which is made from legs/hands connection of featured-swimmer))',
            code: 'y13',
            value: 0.3,
        }, {
            name: '"Twirl of a featured swimmer"',
            code: 'y14',
            value: 0.05,
        }, {
            name: '"Beyonce fall" (from lift blind fall backwards on the other formation made from hands)',
            code: 'y15',
            value: 0.1,
        },
    ],
}
