import {authScope} from "./base";
import {HttpMethods} from "../types/http";

const CHANGE_PASSWORD_URL = `${authScope}/changePassword`;
const REQUEST_RESTORE_TOKEN_URL = `${authScope}/requestRestoreToken`;
const SIGNOUT_EVERYWHERE_URL = `${authScope}/signoutEverywhere`;
const SIGNOUT_URL = `${authScope}/signout`;
const AUTH_URL = `${authScope}/auth`;
const SIGNIN_URL = `${authScope}/signin`;
const SIGNUP_URL = `${authScope}/signup`;
const VALIDATE_REFRESH_TOKEN = `${authScope}/validateRefreshToken`

export const postChangePassword = {
    url: CHANGE_PASSWORD_URL,
    method: HttpMethods.Post,
}

export const postRequestRestoreToken = {
    url: REQUEST_RESTORE_TOKEN_URL,
    method: HttpMethods.Post,
}

export const postSignoutEverywhere = {
    url: SIGNOUT_EVERYWHERE_URL,
    method: HttpMethods.Post,
}

export const postSignout = {
    url: SIGNOUT_URL,
    method: HttpMethods.Post,
}

export const postAuth = {
    url: AUTH_URL,
    method: HttpMethods.Post,
}

export const postSignIn = {
    url: SIGNIN_URL,
    method: HttpMethods.Post,
}

export const postSignUp = {
    url: SIGNUP_URL,
    method: HttpMethods.Post,
}

export const postValidateRefreshToken = {
    url: VALIDATE_REFRESH_TOKEN,
    method: HttpMethods.Post,
}
