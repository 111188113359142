import {GROUP_AW} from "./GROUP_AW";
import {GROUP_BL} from "./GROUP_BL";

export const GROUP_PP = {
    name: 'Group P, Standard',
    code: 'PP',
    description: 'The coordinated effort of team members to form a stable support on which one or more\n' +
        'swimmers is lifted to pose or perform actions. May have jump or “dismount” ending (water\n' +
        'entrance).',
    construction: [
        {
            name: 'Platform (Support straight body)',
            code: 'P',
            value: 1.1,
        }, {
            name: 'Platform “small” (Support straight body)',
            code: '',
            value: 0.85,
        }, {
            name: 'Platform (Support straight body) + bent knees',
            code: 'Knees',
            value: 1.15,
        }, {
            name: 'Platform (Support ballet leg)',
            code: 'B',
            value: 1.3,
        }, {
            name: 'Platform (Support double ballet leg)',
            code: 'DB',
            value: 1.4,
        }, {
            name: 'Platform (Support on stomach in arch position)',
            code: 'a',
            value: 1.15,
        }, {
            name: 'Platform (Support on stomach with bent knees) “Chariot”',
            code: 'Chariot',
            value: 1.15,
        }, {
            name: '"Area" ("box)',
            code: 'Box',
            value: 1.2,
        }, {
            name: 'Platform from 2 supports (1 ballet leg)',
            code: '2SupB',
            value: 1.45,
        }, {
            name: 'Platform from 2 supports (2 ballet legs)',
            code: '2SupBB',
            value: 1.7,
        }, {
            name: 'Float from 2 parallel supports',
            code: '2Sup',
            value: 1.0,
        }, {
            name: 'Float "triangle" (3 swimmers form a support from legs)',
            code: 'Triangle',
            value: 1.1,
        }, {
            name: 'Float “Rhombus” (2 swimmers form a support from legs)',
            code: 'Rhombus',
            value: 0.9,
        }, {
            name: 'Float “star” (5 swimmers form a support from legs) + 2 base is under',
            code: 'Star',
            value: 0.9,
        }, {
            name: 'Float “star” (6 swimmers form a support from legs) + 2 base is under',
            code: 'Star6',
            value: 1.0,
        }, {
            name: 'Float “star” (5 swimmers form a support from legs) + 2 base is under',
            code: 'Start7',
            value: 1.1,
        }, {
            name: 'Platform: float made from hands',
            code: 'Hand',
            value: 0.6,
        }, {
            name: 'Platform + 2 featured-swimmers',
            code: '(2)',
            value: 1.2,
        }, {
            name: 'Float: compass',
            code: 'Compass',
            value: 0.8,
        }, {
            name: 'Platform: float made from hands small',
            code: 'hand',
            value: 0.5,
        }, {
            name: '“Fountain”: 1 base under water + 6 touch/hold featured-swimmer on the surface',
            code: 'Fo',
            value: 0.3,
        }, {
            name: '“Carpet” 1 featured-swimmer make actions on 6 laying supports, other swimmers hold them as base',
            code: 'Carp',
            value: 1.0,
        }, {
            name: '“Carpet” 1 featured-swimmer make actions on 4 laying supports, other swimmers hold them as base',
            code: 'Carp4',
            value: 0.8,
        }, {
            name: 'Platform 4 levels',
            code: 'P4l',
            value: 1.2,
        },
    ],
    direction: [],
    connection: [
        {
            name: 'Sit on straight body (8-9 swimmers or 2-5)',
            code: 'SiA',
            value: 0.05
        }, {
            name: 'Stand (two legs, feet) on straight body',
            code: 'F2A',
            value: 0.1
        }, {
            name: '3 POINTS (Stand 1 leg + 2 hands) on straight body',
            code: '3pA',
            value: 0.1
        }, {
            name: '3 POINTS (Stand on 1 leg + palms/palms connection) (constr: 6-to 9 base swimmers or 2-5) on straight body',
            code: '3pA/',
            value: 0.1
        }, {
            name: 'Stand 1 leg on straight body',
            code: 'FA',
            value: 0.3
        }, {
            name: 'Headstand on straight body',
            code: 'HA',
            value: 0.1
        }, {
            name: 'Golden bridge” grip: Palms (of 1st featured swimmer) and palms+feet (2nd featured swimmer) on straight body',
            code: 'Go',
            value: 0.2
        }, {
            name: 'Head between legs',
            code: 'H+L',
            value: 0.1
        }, {
            name: 'Laying on a straight body',
            code: 'AA',
            value: 0.05
        }, {
            name: 'Stand one leg on palms, one leg on the knees',
            code: 'FP+FK',
            value: 0.2
        }, {
            name: 'Shoulders on palms + catch bent knees',
            code: 'SP+K',
            value: 0.2
        }, {
            name: 'All body (sit or lay) on knees + hand/hands connection',
            code: 'AK/',
            value: 0.2
        }, {
            name: 'Bridge 1 leg on knees and palm',
            code: 'Br1K',
            value: 0.3
        }, {
            name: 'Any 3 point connection with straight body bent knee',
            code: '3pK/',
            value: 0.2
        }, {
            name: 'Stay on straight body + blind connection',
            code: 'F2Ɔb',
            value: 0.2
        }, {
            name: 'Stay on arch featured-swimmers + extra support on head',
            code: 'F2Ɔ+H',
            value: 0.1
        }, {
            name: '“Yin/Yang” (palms on legs+leg/s on palms)',
            code: 'YY',
            value: 0.3
        }, {
            name: 'Sit on feet + feet on back',
            code: 'SiF+FB',
            value: 0.1
        }, {
            name: 'Foot on a ballet leg body + palm/foot',
            code: 'FA+PF',
            value: 0.3
        }, {
            name: 'Sit on 1 foot + feet on palms',
            code: 'SiF+FP',
            value: 0.2
        }, {
            name: 'Sit on 1 foot + palms/palms',
            code: 'SiF/',
            value: 0.3
        }, {
            name: 'Lay on 1 foot + palms/shoulders + shoulders/palms',
            code: 'BF+Le',
            value: 0.2
        }, {
            name: 'Shoulders on palms + connect with leg',
            code: 'SP+L',
            value: 0.4
        }, {
            name: 'Shoulders on palms + hand and knee connection with leg',
            code: 'SP+KF',
            value: 0.3
        }, {
            name: 'Stand (two legs, feet) on ballet leg body +palm on foot',
            code: 'F2A+PF',
            value: 0.1
        }, {
            name: 'Bridge on a ballet leg (foot)+ palms/palms',
            code: '4pF/',
            value: 0.3
        }, {
            name: 'Sit on straight feet + blind palms/palms',
            code: 'SiFb/',
            value: 0.4
        }, {
            name: 'Bridge on Double ballet leg',
            code: 'PF+FP',
            value: 0.4
        }, {
            name: 'Shoulders on feet + extra connection palms/palms',
            code: 'SF/',
            value: 0.3
        }, {
            name: 'Sit or Lay on straight feet + palms/palms',
            code: 'SiF/',
            value: 0.3
        }, {
            name: 'Palms/legs + legs/palms',
            code: 'PL+LP',
            value: 0.2
        }, {
            name: 'Palms on bodies + extra help from base swimmers',
            code: 'PA3*',
            value: 0.2
        }, {
            name: '4 (bridge) or 3 (needle) points of support on legs+ extra help from base swimmers',
            code: '4pA3*',
            value: 0.1
        }, {
            name: 'Feet and palms on hands connection',
            code: 'BrH',
            value: 0.1
        }, {
            name: 'Foot and palms on hands connection',
            code: '3pH',
            value: 0.1
        }, {
            name: 'Shoulders on hands',
            code: 'ShH',
            value: 0.1
        }, {
            name: '2 legs on hands',
            code: 'F2H',
            value: 0.3
        }, {
            name: 'Sit or lay on hands',
            code: 'AH',
            value: 0.05
        }, {
            name: 'All body (Sit, Lay, Head-down or stand) on 6/7/8 straight bodies or Compass or Carpet',
            code: 'AA',
            value: 0.1
        }, {
            name: 'Palms, foot on 2 straight bodies (for example: needle)',
            code: '3pA2',
            value: 0.1
        }, {
            name: 'Palms, foot on Bridge',
            code: 'Br1A2',
            value: 0.1
        }, {
            name: 'Foot on two bodies + palm/foot',
            code: 'FA2+PF',
            value: 0.3
        }, {
            name: '2 legs on 2 bodies: 1 ballet leg + 1 straight body',
            code: 'F2A2+PF',
            value: 0.1
        }, {
            name: 'Foot on a two body + palm/foot + knee/foot',
            code: 'FB2+PF+KF',
            value: 0.1
        }, {
            name: '2 legs on 2 ballet leg bodies',
            code: 'F2B+PF+PF',
            value: 0.1
        }, {
            name: 'Legs on 2 straight bodies',
            code: 'F2A2',
            value: 0.2
        }, {
            name: 'Bridge on 2 straight bodies',
            code: '4pA2',
            value: 0.1
        }, {
            name: '1 leg + 2 hands on 2 straight bodies',
            code: '3pA2',
            value: 0.1
        }, {
            name: '(4 level), 1 leg on shoulders ',
            code: 'FSh',
            value: 0.25
        }, {
            name: '(4 level) sit on shoulders ',
            code: 'SiSh',
            value: 0.05
        }, {
            name: '(4 level) stand on shoulders',
            code: '2LSh',
            value: 0.15
        }, {
            name: '1 foot on hands',
            code: 'F1H',
            value: 0.2
        },
    ],
    position: [
        ...GROUP_AW.position.map((item) => {
            const newItem = {...item};
            newItem.name = `A: ${item.name}`;
            return newItem;
        }),
        ...GROUP_BL.position.map((item) => {
            const newItem = {...item};
            newItem.name = `B: ${item.name}`;
            return newItem;
        })
    ],
    rotationOfBase: [
        {
            name: 'Value for Platform (all construction rotates including base swimmers) 90°',
            code: 'R/',
            value: 0.2
        }, {
            name: 'Value for Platform (all construction rotates including base swimmers) 180°',
            code: 'R0,5',
            value: 0.3
        }, {
            name: 'Value for Stack (only support swimmer with featured-swimmer on top rotates around self) 360°',
            code: 'R1',
            value: 0.4
        }, {
            name: 'Value for Platform (if featured-swimmer sits or in a headstand position, not standing) 90°',
            code: 'R/*',
            value: 0.05,
        }, {
            name: 'Value for Platform (if featured-swimmer sits or in a headstand position, not standing) 180°',
            code: 'R0,5*',
            value: 0.1,
        }, {
            name: 'Value for Platform (if featured-swimmer sits or in a headstand position, not standing) 360°',
            code: 'R1*',
            value: 0.2,
        }, {
            name: 'Value for Float made from hands 180°',
            code: 'R0,5h',
            value: 0.7,
        }, {
            name: 'Value for Float made from hands 360°',
            code: 'R1h',
            value: 0.7,
        }, {
            name: 'Value for Float made from legs (Star, Compass etc.) 90°',
            code: 'R/l',
            value: 0.3,
        }, {
            name: 'Value for Float made from legs (Star, Compass etc.) 180°',
            code: 'R0,5l',
            value: 0.4,
        },
    ],
    rotation: [],
    bonus: [
        {
            name: 'Synchronized actions for double acrobatic movements',
            code: 'j1',
            value: 0.2,
        }, {
            name: 'Connection between 2 featured-swimmers',
            code: 'j2',
            value: 0.1,
        }, {
            name: 'Third position (any additional position 3rd, 4th, 5th will be counted only once)',
            code: 'j3',
            value: 0.05,
        }, {
            name: 'Blind grip between fratured swimmer and support',
            code: 'j4',
            value: 0.1,
        }, {
            name: '“Roll” on the construction and “rolling” (circling action of platform construction, when featured swimmer submerges after 90° and support swimmer follows showing 180° arch-action above surface) entrance in the water',
            code: 'j5',
            value: 0.2,
        }, {
            name: 'Lifting in a “Box” and lowering back',
            code: 'j6',
            value: 0.2,
        }, {
            name: '“Spider” action (Float formation: featured-swimmer twists in the shoulder and thigh joints and appears from underwater on a construction. This action has flexibility risk factor)',
            code: 'j7',
            value: 0.2,
        }, {
            name: 'Floats made from hands, which are “out of water” (not on the surface)',
            code: 'j8',
            value: 0.2,
        }, {
            name: 'Jump (Dive) from platform',
            code: 'j9',
            value: 0.05,
        }, {
            name: '“Cartwheel” on a platform and entering the water',
            code: 'j10',
            value: 0.2,
        }, {
            name: '270° somersault jump from Platform ',
            code: 'j11',
            value: 0.3,
        }, {
            name: 'Move from Platform on to 2 spotter’s heads for finishing acrobatic movement as Lift',
            code: 'j12',
            value: 0.3,
        }, {
            name: 'During platform, featured swimmer breaks palms/ connect witpalmsh support and/or lifting torso and maintain position',
            code: 'j13',
            value: 0.3,
        }, {
            name: '“Spichag” (power press-up from Crocodile to Candle/or Vertical head-down position)',
            code: 'j14',
            value: 0.2,
        }, {
            name: '“Break-dance” movements on a float',
            code: 'j15',
            value: 0.2,
        }, {
            name: '“Porpoise” start-action for featured-swimmer at the beginning of the acrobatic movement to get to the main position.',
            code: 'j16',
            value: 0.1,
        }, {
            name: 'Travelling construction ',
            code: 'j17',
            value: 0.1,
        }, {
            name: 'Lifting up from the surface platform-construction',
            code: 'j18',
            value: 0.1,
        }, {
            name: '“Surfing”, “Riding a wave” (lifting up and down full platform construction (but not away from surface)',
            code: 'j19',
            value: 0.1,
        }, {
            name: 'Climb onto the platform from under the water (inside the construction)',
            code: 'j20',
            value: 0.05,
        }, {
            name: 'Change the “grip”',
            code: 'j21',
            value: 0.05,
        }, {
            name: 'If float was lifted up from under the water and/or submerge after to finish an acrobatic movement ',
            code: 'j22',
            value: 0.1,
        }, {
            name: 'Fast fall down inside floats’ construction',
            code: 'j23',
            value: 0.05,
        }, {
            name: 'Fast fall down inside floats’ construction with twirl 360°',
            code: 'j24',
            value: 0.1,
        }, {
            name: 'Change of featured-swimmer',
            code: 'j25',
            value: 0.1,
        },
    ],
}
