import {utilsScope} from "./base";
import {HttpMethods} from "../types/http";

export const ENCRYPT_ROUTINE_URL = `${utilsScope}/encrypt`
export const DECRYPT_ROUTINE_URL = `${utilsScope}/decrypt`

export const postEncryptRoutine = {
    url: ENCRYPT_ROUTINE_URL,
    method: HttpMethods.Post,
}

export const postDecryptRoutine = {
    url: DECRYPT_ROUTINE_URL,
    method: HttpMethods.Post,
}
