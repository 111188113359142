import React, {useState} from 'react';
import {Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {ACROBATICS_SIMPLE} from "../../../const/acrobatics/ACROBATICS";
import {IAcrobatics, IAcrobaticsComponent, INullableAcrobatics, IRoutineFragment} from "../../../types";
import SaveIcon from "@mui/icons-material/Save";
import {$currentRoutineFragmentStore} from "../../../stores";
import {useStore} from "effector-react";
import {handleAcrobaticsChange} from "../../../stores/currentRoutineFragment/handlers/handleAcrobaticsChange";

type IProps = {
    onOk: () => void
}

const Component = (props: IProps) => {
    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<IAcrobatics>
    const [acrobatics, setAcrobatics] = useState<IAcrobatics>({
        bonus: currentRoutineFragment.content.bonus,
        connection: currentRoutineFragment.content.connection,
        construction: currentRoutineFragment.content.construction,
        direction: currentRoutineFragment.content.direction,
        group: currentRoutineFragment.content.group || '',
        position: currentRoutineFragment.content.position,
        position2: currentRoutineFragment.content.position2,
        rotation: currentRoutineFragment.content.rotation,
        rotationOfBase: currentRoutineFragment.content.rotationOfBase,
        bonus2: currentRoutineFragment.content.bonus2
    });

    const handleGroupChange = (e: SelectChangeEvent) => {
        setAcrobatics({
            group: e.target.value,
            bonus: undefined,
            connection: undefined,
            construction: undefined,
            direction: undefined,
            position: undefined,
            position2: undefined,
            rotation: undefined,
            rotationOfBase: undefined,
            bonus2: undefined
        })
    }

    const isConstructionSelectVisible = acrobatics.group !== '';
    const isPositionSelectVisible = acrobatics.group !== '';
    const isBonusSelectVisible = acrobatics.group !== '';
    const isConnectionSelectVisible = acrobatics.group.startsWith('B') || acrobatics.group.startsWith('P');
    const isDirectionSelectVisible = acrobatics.group.startsWith('A') || acrobatics.group.startsWith('C');
    const isRotationSelectVisible = acrobatics.group.startsWith('A') || acrobatics.group.startsWith('C');
    const isRotationOfBaseSelectVisible = acrobatics.group !== '' && !acrobatics.group.startsWith('A');

    const handleChange = (e: SelectChangeEvent) => {
        const selectedGroup = ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE];
        if (!selectedGroup) return;
        const selectedComponent = selectedGroup[
            e.target.name === 'bonus2' ? 'bonus'
                : e.target.name === "position2" ? 'position'
                    : e.target.name as keyof typeof selectedGroup] as unknown as IAcrobaticsComponent[];
        const selectedElement = selectedComponent.find((item) => item.name === e.target.value)!
        setAcrobatics((state) => {
            const newState = {...state};
            newState[e.target.name as keyof INullableAcrobatics] = selectedElement;
            return newState;
        })
    }

    const saveAcrobatics = () => {
        handleAcrobaticsChange(acrobatics);
        props.onOk();
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            gap: 2,
            width: '30vw',
        }}>
            <FormControl fullWidth>
                <InputLabel id="acrobatics-group-label">Group/Subgroup</InputLabel>
                <Select
                    labelId="acrobatics-group-label"
                    id="acrobatics-group-select"
                    value={acrobatics.group}
                    label="Group"
                    onChange={handleGroupChange}
                >
                    {Object.values(ACROBATICS_SIMPLE).map((item) =>
                        <MenuItem value={item.code}>{item.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
            {isConstructionSelectVisible &&
                <FormControl fullWidth>
                    <InputLabel id="construction-label">Construction</InputLabel>
                    <Select
                        defaultValue=''
                        labelId="construction-label"
                        id="construction-select"
                        value={acrobatics.construction?.name}
                        label="Construction"
                        name="construction"
                        onChange={handleChange}
                    >
                        {ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE]!.construction.map((item) =>
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {isDirectionSelectVisible &&
                <FormControl fullWidth>
                    <InputLabel id="direction-label">Direction</InputLabel>
                    <Select
                        defaultValue=''
                        labelId="direction-label"
                        id="direction-select"
                        value={acrobatics.direction?.name}
                        label="Direction"
                        name='direction'
                        onChange={handleChange}
                    >
                        {ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE]!.direction.map((item) =>
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {isConnectionSelectVisible &&
                <FormControl fullWidth>
                    <InputLabel id="connection-label">Type of connection</InputLabel>
                    <Select
                        defaultValue=''
                        labelId="connection-label"
                        id="connection-select"
                        value={acrobatics.connection?.name}
                        label="Type of connection"
                        name='connection'
                        onChange={handleChange}
                    >
                        {ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE]!.connection.map((item) =>
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {isPositionSelectVisible &&
                <React.Fragment>
                    <FormControl fullWidth>
                        <InputLabel id="position-label">Position</InputLabel>
                        <Select
                            defaultValue=''
                            labelId="position-label"
                            id="position-select"
                            value={acrobatics.position?.name}
                            label="Position"
                            name="position"
                            onChange={handleChange}
                        >
                            {ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE]!.position.map((item) =>
                                <MenuItem value={item!.name}>{item!.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="position2-label">Position 2</InputLabel>
                        <Select
                            defaultValue=''
                            labelId="position2-label"
                            id="position2-select"
                            value={acrobatics.position2?.name}
                            label="Position 2"
                            name="position2"
                            onChange={handleChange}
                        >
                            {ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE]!.position.map((item) =>
                                <MenuItem value={item!.name}>{item!.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </React.Fragment>
            }
            {isRotationOfBaseSelectVisible &&
                <FormControl fullWidth>
                    <InputLabel id="rotation-of-base-label">Rotation of base</InputLabel>
                    <Select
                        defaultValue=''
                        labelId="rotation-of-base-label"
                        id="rotation-of-base-select"
                        value={acrobatics.rotationOfBase?.name}
                        label="Rotation of base"
                        name="rotationOfBase"
                        onChange={handleChange}
                    >
                        {ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE]!.rotationOfBase.map((item) =>
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {isRotationSelectVisible &&
                <FormControl fullWidth>
                    <InputLabel id="rotation-label">Rotation</InputLabel>
                    <Select
                        defaultValue=''
                        labelId="rotation-label"
                        id="rotation-select"
                        value={acrobatics.rotation?.name}
                        label="Rotation"
                        name="rotation"
                        onChange={handleChange}
                    >
                        {ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE]!.rotation.map((item) =>
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            {isBonusSelectVisible &&
                <React.Fragment>
                    <FormControl fullWidth>
                        <InputLabel id="bonus-label">Bonus</InputLabel>
                        <Select
                            defaultValue=''
                            labelId="bonus-label"
                            id="bonus-select"
                            value={acrobatics.bonus?.name}
                            label="Bonus"
                            name="bonus"
                            onChange={handleChange}
                        >
                            {ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE]!.bonus.map((item) =>
                                <MenuItem value={item.name}>{item.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="bonus2-label">Bonus 2</InputLabel>
                        <Select
                            defaultValue=''
                            labelId="bonus2-label"
                            id="bonus2-select"
                            value={acrobatics.bonus2?.name}
                            label="Bonus 2"
                            name="bonus2"
                            onChange={handleChange}
                        >
                            {ACROBATICS_SIMPLE[acrobatics.group as keyof typeof ACROBATICS_SIMPLE]!.bonus.map((item) =>
                                <MenuItem value={item.name}>{item.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </React.Fragment>
            }
            <Button
                variant="contained"
                color="primary"
                fullWidth
                size="small"
                sx={{
                    margin: 1,
                }}
                onClick={saveAcrobatics}
            >
                <SaveIcon
                    sx={{
                        marginRight: 1,
                        fontSize: 20,
                    }}
                />
                Save acrobatics
            </Button>
        </Box>
    )
}

export const SelectAcrobatics = React.memo(Component);
