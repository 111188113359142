import {IAcrobatics, IHybrid, IPairAcrobatics, IRoutineFragment, ITechRoutineTypes, ITRE} from "../types";
import {ROUTINE_FRAGMENT_TYPES} from "../const/ROUTINE_FRAGMENT_TYPES";
import {ROUTINE_TYPES} from "../const/ROUTINE_TYPES";

interface NewFragment {
    startTime: number;
    number: number;
    type: ROUTINE_FRAGMENT_TYPES;
    routine: ROUTINE_TYPES;
    endTime?: number
    hybridType?: 'S' | 'D' | 'T'
}

export const createNewFragment = ({startTime, number, type, routine, hybridType, endTime = 0}: NewFragment) => {
    switch (type) {
        case ROUTINE_FRAGMENT_TYPES.START: {
            const newFragment: IRoutineFragment<undefined> = {
                type: ROUTINE_FRAGMENT_TYPES.START,
                endTime: endTime,
                number: 0,
                startTime: 0,
                content: undefined,
            }
            return newFragment
        }
        case ROUTINE_FRAGMENT_TYPES.TRE: {
            const newFragment: IRoutineFragment<ITRE> = {
                type: ROUTINE_FRAGMENT_TYPES.TRE,
                endTime: endTime,
                number: number,
                startTime: startTime,
                content: {
                    routineType: routine as ITechRoutineTypes,
                    TRENumber: undefined,
                    TREVariant: undefined,
                    description: undefined
                },
            }
            return newFragment
        }
        case ROUTINE_FRAGMENT_TYPES.HYBRID: {
            const newFragment: IRoutineFragment<IHybrid> = {
                type: ROUTINE_FRAGMENT_TYPES.HYBRID,
                endTime: endTime,
                number: number,
                startTime: startTime,
                content: {
                    type: hybridType,
                    numberOfMovements: undefined,
                    modifiers: [],
                    bonuses: [],
                },
            }
            return newFragment
        }
        case ROUTINE_FRAGMENT_TYPES.PAIR_ACROBATICS: {
            const newFragment: IRoutineFragment<IPairAcrobatics> = {
                type: ROUTINE_FRAGMENT_TYPES.PAIR_ACROBATICS,
                endTime: endTime,
                number: number,
                startTime: startTime,
                content: {
                    level: undefined,
                    component: undefined
                },
            }
            return newFragment
        }
        case ROUTINE_FRAGMENT_TYPES.ACROBATICS: {
            const newFragment: IRoutineFragment<IAcrobatics> = {
                type: ROUTINE_FRAGMENT_TYPES.ACROBATICS,
                endTime: endTime,
                number: number,
                startTime: startTime,
                content: {
                    group: '',
                    construction: undefined,
                    connection: undefined,
                    direction: undefined,
                    position: undefined,
                    rotation: undefined,
                    rotationOfBase: undefined,
                    bonus: undefined,
                    bonus2: undefined,

                },
            }
            return newFragment
        }
        case ROUTINE_FRAGMENT_TYPES.TRANSITION: {
            const newFragment: IRoutineFragment<undefined> = {
                type: ROUTINE_FRAGMENT_TYPES.TRANSITION,
                endTime: endTime,
                number: number,
                startTime: startTime,
                content: undefined,
            }
            return newFragment
        }
        default: {
            const newFragment: IRoutineFragment<undefined> = {
                type: ROUTINE_FRAGMENT_TYPES.START,
                endTime: endTime,
                number: 0,
                startTime: startTime,
                content: undefined,
            }
            return newFragment
        }
    }
}
