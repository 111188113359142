import {ROUTINE_FRAGMENT_TYPES} from "./ROUTINE_FRAGMENT_TYPES";

export const ACTIVE_FRAGMENT_COLORS = {
    [ROUTINE_FRAGMENT_TYPES.START]: 'rgba(0,0,0,0.2)',
    [ROUTINE_FRAGMENT_TYPES.HYBRID]: 'rgba(165, 225, 233, 0.4)',
    [ROUTINE_FRAGMENT_TYPES.TRANSITION]: 'rgba(248, 202, 220, 0.4)',
    [ROUTINE_FRAGMENT_TYPES.ACROBATICS]: 'rgba(196, 189, 243, 0.4)',
    [ROUTINE_FRAGMENT_TYPES.PAIR_ACROBATICS]: 'rgba(252, 232, 165, 0.4)',
    [ROUTINE_FRAGMENT_TYPES.TRE]: 'rgba(213, 237, 185, 0.4)',
}
