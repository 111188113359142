import {IRoutineFragment, ITRE} from "../../../types";
import {postCalculateTRE} from "../../../http/calculator";
import {IApiResponse} from "../../../types/http";

export const calculateTRE = async (TRE: IRoutineFragment<ITRE>) => {
    const response = await fetch(postCalculateTRE.url, {
        method: postCalculateTRE.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(TRE),
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.difficulty
}
