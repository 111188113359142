import {createStore, createEvent} from "effector";
import {
    IAcrobatics,
    IBonus,
    IModifier,
    IPairAcrobatics, IRoutineFragmentContentTypes,
    IRoutineFragment,
    ITRE
} from "../../types";
import {ROUTINE_FRAGMENT_TYPES} from "../../const/ROUTINE_FRAGMENT_TYPES";
import {NUMBER_OF_MOVEMENTS} from "../../const/descriptions";

export const setActiveFragmentEvent = createEvent<IRoutineFragment<IRoutineFragmentContentTypes> | null>()
export const addModifierEvent = createEvent<IModifier>()
export const removeModifierEvent = createEvent<IModifier>()
export const removeOneModifierEvent = createEvent<IModifier>()
export const addBonusEvent = createEvent<IBonus>()
export const removeBonusEvent = createEvent<IBonus>()
export const removeOneBonusEvent = createEvent<IBonus>()
export const changeFragmentTypeEvent = createEvent<ROUTINE_FRAGMENT_TYPES>()
export const changeNumberOfMovementsEvent = createEvent<NUMBER_OF_MOVEMENTS>()
export const changeAcrobaticsEvent = createEvent<IAcrobatics>()
export const changePairAcrobaticsEvent = createEvent<IPairAcrobatics>()
export const selectTechnicalElementEvent = createEvent<ITRE>()

export const $currentRoutineFragmentStore = createStore<IRoutineFragment<IRoutineFragmentContentTypes> | null>(null)
