import React from "react";
import {Box} from "@mui/material";
import {useStore} from "effector-react";
import {$routineStore} from "../../../stores";
import {Fragment} from "./Fragment";

const Component = () => {
    const routineStore = useStore($routineStore);

    return (
        routineStore.fragments.length === 0 ? null :
            <Box sx={{
                width: '100%',
                overflowX: 'auto',
                overflowY: 'hidden'
            }}>
                <Box sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'start',
                    justifyContent: 'start',
                }}>
                    {routineStore.fragments.map((data) => {
                        return <Fragment
                            key={`${data.startTime}-${data.endTime}`}
                            data={data}
                        />
                    })}
                </Box>
            </Box>
    )
}

export const ContentLine = React.memo(Component);
