import React, {useEffect, useState} from "react";
import {useStore} from "effector-react";
import {Typography} from "@mui/material";

import {$routineStore} from "../../../stores";
import {calculateRoutine} from "../../../services/apiRequests/calculator";
import {Translation} from "../../../library/common/Translation/Translation";

const Component = () => {
    const routine = useStore($routineStore);
    const [difficulty, setDifficulty] = useState(null);
    useEffect(()=>{
        const calculateRoutineDifficulty = async () => {
            setDifficulty(await calculateRoutine(routine))
        }
        calculateRoutineDifficulty().then();
    },[routine])

    return (
        <Typography align="center" color="textPrimary">
            {difficulty || <Translation nameSpace='common' translation='domain.difficulty' />}
        </Typography>
    )
}

export const Difficulty = React.memo(Component)
