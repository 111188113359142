import {Box, Grid, List, ListItem, ListItemText} from "@mui/material";
import AccessTime from "@mui/icons-material/AccessTime";
import Scoreboard from "@mui/icons-material/Scoreboard"

import React, {useEffect, useState} from "react";
import {useStore} from "effector-react";
import {$currentRoutineFragmentStore, $routineStore} from "../../../stores";
import {ROUTINE_FRAGMENT_TYPES} from "../../../const/ROUTINE_FRAGMENT_TYPES";
import {formatTime} from "../../../services/formatTime";
import {calculateAcrobatics, calculateHybrid} from "../../../services/apiRequests/calculator";
import {calculatePairAcrobatics} from "../../../services/apiRequests/calculator";
import {calculateTRE} from "../../../services/apiRequests/calculator";
import {
    IAcrobatics,
    IHybrid,
    IPairAcrobatics,
    IRoutineFragment,
    IRoutineFragmentContentTypes,
    ITRE
} from "../../../types";

const Component = () => {
    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<IRoutineFragmentContentTypes>;
    const routine = useStore($routineStore);
    const length = currentRoutineFragment!.endTime - currentRoutineFragment!.startTime;
    const [difficulty, setDifficulty] = useState(0);
    useEffect(()=>{
        const calculateFragmentDifficulty = async () => {
            switch (currentRoutineFragment.type) {
                case ROUTINE_FRAGMENT_TYPES.ACROBATICS: {
                    setDifficulty(await calculateAcrobatics(currentRoutineFragment as IRoutineFragment<IAcrobatics>))
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.PAIR_ACROBATICS:{
                    setDifficulty(await calculatePairAcrobatics(currentRoutineFragment as IRoutineFragment<IPairAcrobatics>))
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.HYBRID: {
                    setDifficulty(await calculateHybrid(currentRoutineFragment as IRoutineFragment<IHybrid>))
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.TRE: {
                    setDifficulty(await calculateTRE(currentRoutineFragment as IRoutineFragment<ITRE>))
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.START: {
                    setDifficulty(0)
                    break;
                }
                case ROUTINE_FRAGMENT_TYPES.TRANSITION: {
                    setDifficulty(0)
                    break;
                }
                default: {
                    setDifficulty(0)
                    break;
                }
            }
        }
        calculateFragmentDifficulty().then();
    },[currentRoutineFragment, routine.type])


    return (
        <Grid sx={{
            padding: 2,
            color: "text.secondary",
        }} container alignItems={"stretch"} justifyContent={"center"}>
            <Box sx={{width: "100%"}}>
                <List
                    component="nav"
                    sx={{
                        width: '100%',
                        backgroundColor: "background.paper",
                    }}
                >
                    <ListItem>
                        <Scoreboard sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}/>
                        <ListItemText
                            primary={difficulty}/>
                    </ListItem>
                    <ListItem>
                        <AccessTime sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}/>
                        <ListItemText
                            primary={length > 0 ? formatTime(length) : 'Not finished'}/>
                    </ListItem>
                </List>
            </Box>
        </Grid>
    )
}

export const MainInformation = React.memo(Component);
