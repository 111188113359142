import {IApiResponse} from "../../../types/http";
import {postDecryptRoutine} from "../../../http/utils";

export const decryptRoutine = async (routine: ArrayBuffer) => {
    const response = await fetch(postDecryptRoutine.url, {
        method: postDecryptRoutine.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({data: Array.from(new Uint8Array(routine))}),
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.data
}
