import React from "react";
import {Button, List, ListItem} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {useStore} from "effector-react";
import {$currentRoutineFragmentStore} from "../../../stores";
import {handleRemoveFragment} from "../../../stores/routine/handlers/handleRemoveFragment";
import {handleSetActiveFragment} from "../../../stores/currentRoutineFragment/handlers/handleSetActiveFragment";
import {ROUTINE_FRAGMENT_TYPES} from "../../../const/ROUTINE_FRAGMENT_TYPES";
import {Translation} from "../../../library/common/Translation/Translation";
import {IRoutineFragment, IRoutineFragmentContentTypes} from "../../../types";
import {TREActions} from "./Actions/TREActions";
import {HybridActions} from "./Actions/HybridActions";
import {AcrobaticsActions} from "./Actions/AcrobaticsActions";
import {PairAcrobaticsActions} from "./Actions/PairAcrobaticActions";

const Component = () => {
    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<IRoutineFragmentContentTypes>;

    const deleteFragment = () => {
        handleRemoveFragment(currentRoutineFragment!);
        handleSetActiveFragment(null);
    }

    const actions = ()=>{
        switch (currentRoutineFragment?.type) {
            case ROUTINE_FRAGMENT_TYPES.TRE:
                return <TREActions />;
            case ROUTINE_FRAGMENT_TYPES.HYBRID:
                return <HybridActions />
            case ROUTINE_FRAGMENT_TYPES.ACROBATICS:
                return <AcrobaticsActions/>
            case ROUTINE_FRAGMENT_TYPES.PAIR_ACROBATICS:
                return <PairAcrobaticsActions />
            default:
                return null;
        }
    }

    return (
        <List>
            {actions()}
            <ListItem>
                <Button
                    onClick={deleteFragment}
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    sx={{
                        margin: 1,
                    }}
                >
                    <DeleteIcon
                        sx={{
                            marginRight: 1,
                            fontSize: 20,
                        }}
                    />
                    <Translation nameSpace='common' translation={'buttons.deleteFragment'}/>
                </Button>
            </ListItem>
        </List>
    )
}

export const Actions = React.memo(Component)
