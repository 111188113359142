import {TRE_VARIANTS} from "./TRE_VARIANTS";
import {ROUTINE_TYPES} from "../ROUTINE_TYPES";

export const MIXED_DUET_TECHNICAL_ELEMENTS = {
    TRE1a: {
        routineType: ROUTINE_TYPES.TECH_MIXED,
        TRENumber: 1,
        TREVariant: TRE_VARIANTS.A,
        description: 'Rocket Split Twirl Spin 180°',
    },
    TRE1b: {
        routineType: ROUTINE_TYPES.TECH_MIXED,
        TRENumber: 1,
        TREVariant: TRE_VARIANTS.B,
        description: 'Rocket Split Twirl',
    },
    TRE2a: {
        routineType: ROUTINE_TYPES.TECH_MIXED,
        TRENumber: 2,
        TREVariant: TRE_VARIANTS.A,
        description: 'Front Pike – Vertical 360° Rotation - Full Twist to Bent Knee - Continuous Spin 720°',
    },
    TRE2b: {
        routineType: ROUTINE_TYPES.TECH_MIXED,
        TRENumber: 2,
        TREVariant: TRE_VARIANTS.B,
        description: 'Front Pike – Vertical 180° Rotation – 1/2 Twist to Bent Knee - Continuous Spin 720°',
    },
    TRE3: {
        routineType: ROUTINE_TYPES.TECH_MIXED,
        TRENumber: 3,
        TREVariant: TRE_VARIANTS.NONE,
        description: 'London Hybrid',
    },
    TRE4a: {
        routineType: ROUTINE_TYPES.TECH_MIXED,
        TRENumber: 4,
        TREVariant: TRE_VARIANTS.A,
        description: 'Nova Hybrid – Half Twist – Continuous Spin 1080°',
    },
    TRE4b: {
        routineType: ROUTINE_TYPES.TECH_MIXED,
        TRENumber: 4,
        TREVariant: TRE_VARIANTS.B,
        description: 'Nova Hybrid – Continuous Spin 1080°',
    },
    TRE5a: {
        routineType: ROUTINE_TYPES.TECH_MIXED,
        TRENumber: 5,
        TREVariant: TRE_VARIANTS.A,
        description: 'Thrust Fishtail Hybrid Bent Knee to Vertical Spinning 180°',
    },
    TRE5b: {
        routineType: ROUTINE_TYPES.TECH_MIXED,
        TRENumber: 5,
        TREVariant: TRE_VARIANTS.B,
        description: 'Thrust Fishtail Helicopter Spinning 180°',
    },
}
