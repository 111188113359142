import React from "react";
import {useStore} from "effector-react";
import {$currentRoutineFragmentStore} from "../../../../stores";
import {Box, Grid, List, ListItem, ListItemText, ListSubheader} from "@mui/material";
import PoolIcon from "@mui/icons-material/Pool";
import {Translation} from "../../../../library/common/Translation/Translation";
import {IPairAcrobatics, IRoutineFragment} from "../../../../types";

const Component = () => {
    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<IPairAcrobatics>;

    return (
        <Grid container alignItems={"stretch"} justifyContent={"center"} sx={{
            padding: 2,
            color: 'text.secondary',
        }}>
            <Box sx={{
                width: "100%"
            }}>
                <List
                    component="nav"
                    subheader={
                        <ListSubheader component="div">
                            <Translation nameSpace='common' translation="domain.modifiers"/>
                        </ListSubheader>
                    }
                    sx={{
                        width: '100%',
                        backgroundColor: 'background.paper',
                    }}
                >
                    <ListItem>
                        <PoolIcon
                            sx={{
                                marginRight: 1,
                                fontSize: 20,
                            }}
                        />
                        <ListItemText primary={`Level: ${currentRoutineFragment.content.level || 'not defined'}`}/>
                    </ListItem>
                    <ListItem>
                        <PoolIcon
                            sx={{
                                marginRight: 1,
                                fontSize: 20,
                            }}
                        />
                        <ListItemText primary={`Name: ${currentRoutineFragment.content.component?.name || 'not defined'}`}/>
                    </ListItem>
                    <ListItem>
                        <PoolIcon
                            sx={{
                                marginRight: 1,
                                fontSize: 20,
                            }}
                        />
                        <ListItemText primary={`Code: ${currentRoutineFragment.content.component?.code || 'not defined'}`}/>
                    </ListItem>
                </List>
            </Box>
        </Grid>
    )
}

export const PairAcrobaticsContent = React.memo(Component)
