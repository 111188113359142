import React from 'react';
import {Box} from "@mui/material";
import {BONUS_TYPES} from "../../../const/BONUS_TYPES";
import {BONUS_CODES} from "../../../const/codes/BONUSES";
import {BONUSES} from "../../../const/descriptions";
import {Bonus} from "./Bonus";

const Component = () => {
    return (
        <Box sx={{
            flexGrow: 1,
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            minWidth: 0,
            gap: 2,
            padding: 2,
        }}>

            {Object.values(BONUS_TYPES).map((bonus: BONUS_TYPES) => {
                const fullName = bonus;
                const code = BONUS_CODES[bonus];
                const description = BONUSES[bonus];
                const data = {code, fullName, description}
                return <Bonus
                    data={data}
                />
            })}
        </Box>
    )
}

export const SelectBonuses = React.memo(Component);
