import React, {useRef, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardActionArea,
    CardContent,
    IconButton,
    Popover,
    Tooltip,
    Typography
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {useStore} from "effector-react";

import {IHybrid, IModifier, IRoutineFragment} from "../../../types";
import {ACTIVE_FRAGMENT_COLORS} from "../../../const/ACTIVE_FRAGMENT_COLORS";
import {handleAddModifier} from "../../../stores/currentRoutineFragment/handlers/addModifier";
import {handleRemoveModifier} from "../../../stores/currentRoutineFragment/handlers/removeModifer";
import {handleRemoveOneModifier} from "../../../stores/currentRoutineFragment/handlers/removeOneModifier";
import {$currentRoutineFragmentStore, $routineStore} from "../../../stores";
import {ROUTINE_FRAGMENT_TYPES} from "../../../const/ROUTINE_FRAGMENT_TYPES";
import {ROUTINE_TYPES} from "../../../const/ROUTINE_TYPES";
import {isDuet} from "../../../services/isDuet";
import {FAMILY_TYPES} from "../../../const/FAMILY_TYPES";

type IProps = {
    data: IModifier
}

const Component = (props: IProps) => {
    const cardRef = useRef(null);
    const [multiplierAnchorEl, setMultiplierAnchorEl] = useState<HTMLDivElement | null>(null);
    const isMultiplierPopoverOpen = Boolean(multiplierAnchorEl);
    const routine = useStore($routineStore);
    const hasMultiplier = (
                routine.type === ROUTINE_TYPES.FREE_COMBO ||
                routine.type === ROUTINE_TYPES.TECH_TEAM ||
                routine.type === ROUTINE_TYPES.FREE_TEAM ||
                routine.type === ROUTINE_TYPES.FREE_HIGHLIGHT ||
                routine.type === ROUTINE_TYPES.FREE_DUET ||
                routine.type === ROUTINE_TYPES.FREE_MIXED ||
                routine.type === ROUTINE_TYPES.TECH_DUET ||
                routine.type === ROUTINE_TYPES.TECH_MIXED
            ) &&
            props.data.family !== FAMILY_TYPES.CONNECTIONS &&
            props.data.family !== FAMILY_TYPES.CONNECTIONS_PLUS
    ;

    const currentRoutineFragment = useStore($currentRoutineFragmentStore) as IRoutineFragment<IHybrid>
    const isActive = currentRoutineFragment.content.modifiers.some((el) => el.fullName === props.data.fullName);
    const count = currentRoutineFragment.content.modifiers.filter((el) => el.fullName === props.data.fullName).length;
    const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (!isActive) {
            if (hasMultiplier) {
                openMultiplierPopover()
            } else {
                addModifier()
            }
        } else {
            removeAllModifiers()
        }
    }

    const addModifier = (multiplier?: 0.3 | 0.5) => {
        if (multiplier) {
            const hybrid = {...props.data, multiplier}
            handleAddModifier(hybrid)
        } else {
            handleAddModifier(props.data)
        }
        closeMultiplierPopover();
    }

    const removeAllModifiers = (event?: React.MouseEvent<HTMLDivElement>) => {
        if (event) event.stopPropagation();
        handleRemoveModifier(props.data)
    }

    const openMultiplierPopover = (event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        if (event) event.stopPropagation();
        setMultiplierAnchorEl(cardRef.current);
    };

    const closeMultiplierPopover = () => {
        setMultiplierAnchorEl(null);
    };

    const handlePlus = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        handleAddModifier(props.data)
    }

    const handleMinus = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        handleRemoveOneModifier(props.data)
    }

    return (
        <React.Fragment>
            <Card
                ref={cardRef}
                raised
                onClick={handleCardClick}
                sx={{
                    margin: 1,
                    background: isActive ? ACTIVE_FRAGMENT_COLORS[ROUTINE_FRAGMENT_TYPES.HYBRID] : 'white',
                    maxWidth: '100%'
                }}
            >
                <CardActionArea>
                    <CardContent>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <Tooltip slotProps={{
                                tooltip: {
                                    sx: {
                                        whiteSpace: 'pre-wrap',
                                    }
                                }
                            }} title={props.data.description}>
                                <InfoOutlinedIcon/>
                            </Tooltip>
                            {isActive ?
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <IconButton sx={{padding: 0}} onClick={handleMinus} disabled={count <= 1}>
                                        <ArrowLeftIcon/>
                                    </IconButton>
                                    <Typography sx={{height: '24px'}}>{count}</Typography>
                                    <IconButton sx={{padding: 0}} onClick={
                                        hasMultiplier
                                            ? openMultiplierPopover
                                            : handlePlus
                                    }>
                                        <ArrowRightIcon/>
                                    </IconButton>
                                </Box>
                                : null}
                        </Box>
                        <Typography align="center" variant="h5" component="h2" textTransform='uppercase'>
                            {props.data.fullName}
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 4
                        }}>
                            <Typography align="center" color="textSecondary">
                                {props.data.family}
                            </Typography>
                            <Typography align="center" color="textSecondary">
                                {props.data.level}
                            </Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Popover
                anchorEl={multiplierAnchorEl}
                open={isMultiplierPopoverOpen}
                onClose={closeMultiplierPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <ButtonGroup>
                    {!isDuet(routine.type) &&
                        <Button onClick={() => addModifier(0.3)}>Less than half</Button>
                    }
                    <Button onClick={() => addModifier(0.5)}>Half and more</Button>
                    <Button onClick={() => addModifier()}>All</Button>
                </ButtonGroup>
            </Popover>

        </React.Fragment>
    )
}

export const Modifier = React.memo(Component)
