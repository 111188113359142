import {IApiResponse} from "../../../types/http";
import {postChangePassword} from "../../../http/auth";

export type IUser = {
    email: string,
    password: string
}
// @ts-ignore
export const changePassword = async (password: string, token: string) => {
    const response = await fetch(postChangePassword.url, {
        method: postChangePassword.method,
        headers: {
            'Content-Type': 'application/json',
            'token': token,
        },
        body: JSON.stringify({password}),
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result
}
