import {IApiResponse} from "../../../types/http";
import {postValidateRefreshToken} from "../../../http/auth";

export const validateRefreshToken = async (token: string) => {
    const response = await fetch(postValidateRefreshToken.url, {
        method: postValidateRefreshToken.method,
        headers: {
            'Content-Type': 'application/json',
            'token': token
        },
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.token
}
