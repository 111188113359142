import { memo } from 'react';

import { useTranslation } from '../../../hooks/useTranslation';

import { TranslationProps } from './Translation.types';

const Component = memo(({ nameSpace, translation: ts, options = {} }: TranslationProps) => {
  const { translation } = useTranslation(nameSpace);
  if(!nameSpace || !ts) return null;
  return <>{translation(ts, options)}</>;
});

export const Translation = memo(Component);
