import {IRoutine} from "../../../types";
import {IApiResponse} from "../../../types/http";
import {postGetCoachCard} from "../../../http/pdf";
import i18n from "../../../i18n";

export const getCoachCard = async (routine: IRoutine) => {
    const response = await fetch(postGetCoachCard.url, {
        method: postGetCoachCard.method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({routine: routine, lang: i18n.resolvedLanguage}),
    })
    const json = await response.json() as IApiResponse;
    if(json.result) return json.result.data
}
