import React, {useEffect, useRef, useState} from "react";
import ReactPlayer from "react-player";
import {OnProgressProps} from "react-player/base";
import {useStore} from "effector-react";
import {Box, Card, Grid, Input, MenuItem, Slider, TextField, Typography} from "@mui/material";

import {ContentLine} from "./Workplace/ContentLine";
import {Controls} from "./Workplace/Controls";
import {ROUTINE_TYPES} from "../../const/ROUTINE_TYPES";
import {$routineStore, $mediaStore, $currentRoutineFragmentStore} from "../../stores";
import {handleChangeType} from "../../stores/routine/handlers/handleChangeType";
import {createNewFragment} from "../../services/createNewFragment";
import {ROUTINE_FRAGMENT_TYPES} from "../../const/ROUTINE_FRAGMENT_TYPES";
import {handleStartFragment} from "../../stores/routine/handlers/handleStartFragment";
import {handleEndFragment} from "../../stores/routine/handlers/handleEndFragment";
import {OverallTime} from "./Workplace/OverallTime";
import {Difficulty} from "./Workplace/Difficulty";
import {Translation} from "../../library/common/Translation/Translation";
import {handleSetActiveFragment} from "../../stores/currentRoutineFragment/handlers/handleSetActiveFragment";

const Component = () => {
    const videoRef = useRef<ReactPlayer>(null)
    const routine = useStore($routineStore)
    const currentRoutineFragment = useStore($currentRoutineFragmentStore);
    const media = useStore($mediaStore);
    const [isVideoPaused, setIsVideoPaused] = useState(true);
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const [videoCurrentTime, setVideoCurrentTime] = useState(0);
    const [videoCurrentPercent, setVideoCurrentPercent] = useState(0);
    const [offsetTime, setOffsetTime] = useState(0);

    useEffect(() => {
        if (currentRoutineFragment) {
            handleTimeChange(currentRoutineFragment.startTime + offsetTime)
        }
    }, [currentRoutineFragment, offsetTime]);

    const handleTypeChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        handleChangeType(e.target.value as ROUTINE_TYPES)
    }

    const handleRoutineStart = () => {
        handlePauseClick();
        setOffsetTime(videoCurrentTime);
        const startFragment = createNewFragment({
            startTime: 0,
            number: 0,
            type: ROUTINE_FRAGMENT_TYPES.START,
            routine: routine.type
        })
        handleStartFragment(startFragment);
    }

    const handleNewFragment = (type: ROUTINE_FRAGMENT_TYPES, hybridType?: 'S' | 'D' | 'T') => {
        handlePauseClick();
        handleEndFragment(videoCurrentTime);
        const newFragment = createNewFragment({
            startTime: videoCurrentTime - offsetTime,
            number: routine.fragments.length,
            type: type,
            hybridType: hybridType,
            routine: routine.type
        });
        handleStartFragment(newFragment);
    }

    const handleRoutineFinish = () => {
        handlePauseClick();
        handleEndFragment(videoCurrentTime - offsetTime)
        handleSetActiveFragment(null);
    }

    const handlePauseClick = () => {
        setIsVideoPaused(true);
        videoRef.current?.getInternalPlayer().pause()
    }

    const handlePlayClick = () => {
        setIsVideoPaused(false);
        videoRef.current?.getInternalPlayer().play()
    }

    const handleForwardClick = () => {
        if (videoRef.current) {
            videoRef.current.getInternalPlayer().currentTime += 5
        }
    }

    const handleBackwardClick = () => {
        if (videoRef.current) {
            videoRef.current.getInternalPlayer().currentTime -= 5
        }
    }

    const handleVolumeChange = (value: number) => {
        if (videoRef.current) {
            videoRef.current.getInternalPlayer().volume = value
        }
    }

    const handleMuteClick = () => {
        setIsVideoMuted(true)
        if (videoRef.current) {
            videoRef.current.getInternalPlayer().muted = true
        }
    }

    const handleUnmuteClick = () => {
        setIsVideoMuted(false)
        if (videoRef.current) {
            videoRef.current.getInternalPlayer().muted = false
        }
    }

    const displayTimeChange = (e: OnProgressProps) => {
        setVideoCurrentTime(e.playedSeconds);
        setVideoCurrentPercent(e.played * 100);
    }

    const handlePercentChange = (_e: Event, value: number | number[]) => {
        const newPercent = value as number
        let newTime = videoRef.current?.getInternalPlayer().duration * (newPercent / 100);

        setVideoCurrentTime(newTime);
        setVideoCurrentPercent(newPercent);
        if (videoRef.current) {
            videoRef.current.getInternalPlayer().currentTime = newTime
        }
    }

    const handleTimeChange = (newTime: number) => {
        let newPercent = (newTime / videoRef.current?.getInternalPlayer().duration) * 100;
        setVideoCurrentTime(newTime);
        setVideoCurrentPercent(newPercent);
        if (videoRef.current) {
            videoRef.current.getInternalPlayer().currentTime = newTime
        }

    }

    return (
        <Box sx={{
            padding: 2,
            color: 'text.secondary',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'stretch',
            overflow: 'hidden',
            gap: 1,
        }}>
            <Typography variant='body2' textAlign='center' sx={{border: '1px solid red'}}>Вопросы и предложения
                отправляйте на почту: il5498@yandex.ru</Typography>
            <Grid container>
                <Grid
                    sx={{
                        padding: 2,
                        color: 'text.secondary',
                        backgroundColor: "white",
                        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)",
                    }} item xs={3}>
                    <Difficulty/>
                </Grid>
                <Grid sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 2,
                    paddingRight: 2,
                    color: 'text.secondary',
                }} item xs={6}>
                    <TextField
                        id="select-type"
                        select
                        fullWidth
                        sx={{margin: "0", padding: 0, textTransform: "capitalize"}}
                        label={<Translation nameSpace='common' translation="domain.routineType"/>}
                        value={routine.type}
                        onChange={handleTypeChange}
                        SelectProps={{
                            MenuProps: {
                                sx: {
                                    textTransform: "capitalize"
                                }
                            },
                        }}
                    >
                        {Object.values(ROUTINE_TYPES).map((option: ROUTINE_TYPES) => (
                            <MenuItem
                                sx={{
                                    textTransform: "capitalize"
                                }}
                                key={option}
                                value={option}
                            >
                                <Translation nameSpace={'common'} translation={`routineTypes.${option}`}/>
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid sx={{
                    padding: 2,
                    color: 'text.secondary',
                    backgroundColor: "white",
                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)"
                }} item xs={3}>
                    <OverallTime/>
                </Grid>
            </Grid>
            <Box sx={{
                backgroundColor: "white",
                boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 3px 3px 0px rgba(0,0,0,0.12)",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'stretch',
                overflow: 'hidden',
                height: '100%',
            }}>
                <Box
                    display={{
                        xs: 'none',
                        sm: 'none',
                        md: 'flex'
                    }}
                    sx={{
                        borderRadius: 4,
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        flexGrow: 1,
                        maxHeight: '60vh',
                    }}
                >
                    {media.type === "audio" &&
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            backgroundColor: 'black',
                            color: 'white',
                            flexGrow: 1,
                        }}>
                            <Typography variant='h1' sx={{
                                userSelect: 'none'
                            }}>
                                {new Date(videoCurrentTime * 1000).toISOString().substr(14, 9)}
                            </Typography>
                        </Box>
                    }
                    <ReactPlayer
                        ref={videoRef}
                        alt="Contemplative Reptile"
                        progressInterval={1}
                        onProgress={displayTimeChange}
                        url={media.src}
                        title="Contemplative Reptile"
                        poster="/video-placeholder.png"
                        width={media.type === 'audio' ? 0 : 'auto'}
                        height={media.type === 'audio' ? 0 : '50vh'}
                        style={{
                            textAlign: 'center'
                        }}
                    />
                </Box>
                <Box sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    maxWidth: '100%',
                    minHeight: '120px'
                }}>
                    <Controls
                        paused={isVideoPaused}
                        muted={isVideoMuted}
                        handlePauseClick={handlePauseClick}
                        handlePlayClick={handlePlayClick}
                        handleForwardClick={handleForwardClick}
                        handleBackwardClick={handleBackwardClick}
                        handleMuteClick={handleMuteClick}
                        handleUnmuteClick={handleUnmuteClick}
                        handleVolumeChange={handleVolumeChange}
                        handleRoutineStart={handleRoutineStart}
                        handleNewFragment={handleNewFragment}
                        handleRoutineFinish={handleRoutineFinish}
                    />
                    <ContentLine/>
                </Box>
                <Card sx={{
                    marginTop: 1,
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'flex'
                    },
                    width: "100%",
                    padding: 1
                }}>
                    <Box
                        sx={{
                            width: '100%',
                            margin: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'stretch',
                            gap: 1,
                        }}>
                        <Slider
                            min={0}
                            max={100}
                            step={0.01}
                            onChange={handlePercentChange}
                            onMouseUp={handlePlayClick}
                            onMouseDown={handlePauseClick}
                            value={videoCurrentPercent}
                            aria-labelledby="continuous-slider"/>
                        <Input
                            value={new Date(videoCurrentTime * 1000).toISOString().substr(14, 9)}
                            sx={{
                                width: '10%',
                            }}
                            margin="dense"
                            disableUnderline
                            disabled
                        />
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

export const Workplace = React.memo(Component)
