export enum AIRBORNE_WEIGHT_LEVELS {
    LEVEL_1 = `Vertical descent in Bent Knee Vertical Position or Vertical descent from Fishtail join to Vertical Position\n
Front Pike to Bent Knee Vertical Position or Fishtail`,
    LEVEL_2 = `Vertical descent in Vertical Position or descending Vertical Position performing isolated movements\n
Front Pike to Vertical Position`,
    LEVEL_3 = `Vertical ascent with 1 or 2 legs\n
Ascending VP performing isolated movements`,
    LEVEL_4 = `Sustained height with one leg or a combination of one and two legs lasting equal or more than 3 seconds`,
    LEVEL_5 = `Isolated movements performed in stable Fishtail Position and piked body position (legs
over surface, 30 to 60º from vertical) lasting equal or more than 3 seconds. Any
knee/leg movements performed credited in number of movements`,
    LEVEL_6 = `Sustained height in Vertical Position lasting equal or more than 3 seconds`,
    LEVEL_7 = `Sustained height shown at least 3 seconds in Vertical Position performed in an
unbalanced position. Any knee/leg movements performed credited in number of
movements`
}
